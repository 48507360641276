import {useQuery} from "react-query";
import {GetQueryKey} from "../../utils";
import {cacheTime} from "../../constants/common";
import commonFunctions from "../../utils/CommonFunctions";

type CitiesQueryParams = {
    countryName: string
}

export const UseGetCountries = () =>
    useQuery(GetQueryKey("worldCountries"), async () => await commonFunctions.loadCountries(), {staleTime: cacheTime.long});

export const UseGetCities = (options: CitiesQueryParams) =>
    useQuery(GetQueryKey("worldCities", options), async () => await commonFunctions.loadCities(options.countryName), {staleTime: cacheTime.long});
