import React, {Fragment, } from 'react'
import {Menu, Transition} from '@headlessui/react'
import {BsChevronDown} from "react-icons/bs";
import {To, useNavigate} from "react-router-dom";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}
type CustomMenuItemProps = {
    title?: string | React.ReactNode
    icon?: React.ReactNode
    link?: To
    onClick?: Function
}

type Props = {
    title: string
    titleComponent?: React.ReactNode
    position?: "right" | "left"
    menus: Array<Array<{ title?: string | React.ReactNode, type?: string, menuItem?: CustomMenuItemProps}>>
    type?: "default" | "custom"
}

type DefaultTitleProps = {
    title: string
}

type CustomTitleProps = {
    element: React.ReactNode
}

const DefaultTitle: React.FC<DefaultTitleProps> = (props: DefaultTitleProps) => {
    return (
        <Menu.Button
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            {props.title} <BsChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
        </Menu.Button>
    )
}

const CustomTitle: React.FC<CustomTitleProps> = (props: CustomTitleProps) => {
    return (
        <Menu.Button
            className="inline-flex justify-center w-full">
            {props.element}
        </Menu.Button>
    )
}

const CustomMenuItem: React.FC<CustomMenuItemProps> = (props: CustomMenuItemProps) => {
    const navigate = useNavigate()
    const handleClick = () => {
        if(props.link){
            navigate(props.link)
            return
        }
        if(props.onClick){
            props.onClick()
            return
        }
    }
    return(
        <div className="flex justify-left items-center cursor-pointer dark:text-white px-4 py-2" onClick={handleClick}>
            {props.icon && <span className="pr-3 text-lg font-bold text-slate-400">{props.icon}</span>}
            {props.title}
        </div>
    )
}

const Dropdown: React.FC<Props> = (props: Props) => {
    const {title, type = "button", position = "right", menus, titleComponent} = props
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                {type === "button" && <DefaultTitle title={title}/>}
                {type === "custom" && <CustomTitle element={titleComponent}/>}
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`origin-top-right z-10 absolute ${position === "right" && "right-0"} mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-slate-900 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-700 focus:outline-none`}>
                    {
                        menus.map((menu, index: number) => {
                            return (
                                <div className="py-1" key={index}>
                                    {menu.map((menuItem, i) => {
                                        const type =  menuItem.type ?  menuItem.type : "default"
                                        const item = {...menuItem, type}
                                        return (
                                            <Menu.Item key={i}>
                                                {({active}) => (
                                                    <span
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-slate-900 text-gray-900 dark:hover:bg-gray-800' : 'text-gray-700',
                                                            'block  text-sm cursor-pointer',
                                                            item.type === "default" ? 'px-4 py-2' : ''
                                                        )}
                                                    >
                                                        {item.type === "default" && item.title}
                                                        {item.type === "custom" && item.menuItem && <CustomMenuItem
                                                            title={item.menuItem.title}
                                                            icon={item.menuItem.icon}
                                                            onClick={item.menuItem.onClick}
                                                            link={item.menuItem.link}
                                                        />}
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Dropdown
