import {useQuery} from "react-query";
import {FormatUrlParams, GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type QueryParams = {
    status?: string
}

export const UseGetSalaryMethods = (options?: QueryParams) =>
    useQuery(GetQueryKey("salaryMethods", options), async () => https.get(`${endpoints.salaryMethods}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});
