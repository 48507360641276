import {motion} from "framer-motion";
import {AppButton, BlockingModal, FileDropZone, LottieFile, Spinner, Stepper, TitleBar} from "../../components";
import {newJobSteps} from "../../constants/common";
import React, {useState} from "react";
import routes from "../../constants/routes";
import {useLocation, useNavigate} from "react-router-dom";
import {UseSaveJob} from "../../services";

const JobsCreateStepFour = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const documentData = state?.documentData
    const processData = state?.processData
    if (!processData) window.location.href = routes.jobCreateRecruitmentProcess

    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const {mutate: saveJob, isLoading: isSaving, data: saveData} = UseSaveJob()

    const onSave = (published: boolean) => {
        setIsPopupVisible(false)

    }

    return (
        <div>
            <TitleBar
                title="Créer une offre d'emploi"
                subTitle="Remplissez tous les champs requis, enregistrer progressivement et publier votre offre des qu'elle est prete"
            />
            <BlockingModal
                isOpen={isPopupVisible}
                title="Voulez-vous publier cette offre ?"
                description={<p className="text-xs mb-6">Publier cette offre sur le portail pour recevoir des
                    candidatures.</p>}
                validateLabel={"Oui, publier l'offre"}
                cancelLabel={"Non, plus tard"}
                onCancel={() => onSave(false)}
                onValidate={() => onSave(true)}
            />
            <div className="grid grid-cols-4 px-5 py-5 gap-x-7">
                <div className="col-span-1">
                    <Stepper type="metromode" steps={newJobSteps} currentStep={4}/>
                </div>
                <motion.div className="col-span-3"
                            initial={{width: 0}}
                            animate={{width: "100%"}}
                            exit={{x: window.innerWidth, transition: {duration: 0.2}}}
                >
                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-0 font-bold text-lg">Documents de l'offre d'emploi</h4>
                        <p className="text-xs mb-4 text-gray-500">Attachez les documents relatifs a l'offre. Ex: le
                            descriptif, le job description, une photo...</p>
                        <div className="my-4">
                            <FileDropZone/>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <AppButton
                            size="md"
                            type="submit"
                            className="w-[30%] rounded-[50px] py-4"
                            bgColor="bg-white border hover:border-gray-600 hover:bg-gray-600"
                            textColor="text-gray-600 hover:text-white"
                            label="Précédent"
                            onClick={() => navigate(routes.jobCreateRecruitmentProcess, {state: {...state}})}
                        />

                        <AppButton
                            size="md"
                            onClick={() => setIsPopupVisible(true)}
                            className="w-[50%] rounded-[50px] py-4"
                            bgColor="bg-primary-base hover:bg-primary-hover"
                            textColor="text-white"
                            label="Enregistrer"
                        />
                    </div>

                </motion.div>
            </div>
        </div>
    )
}

export default JobsCreateStepFour
