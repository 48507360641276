import {forwardRef, LegacyRef, useState} from "react";
import {BsEye, BsEyeSlash} from "react-icons/bs"
import {InputFormType} from "../../types/CommonTypes";
import InputHelperMessage from "./InputHelperMessage";

const AppInput: React.FC<InputFormType> = forwardRef((props: InputFormType, ref: LegacyRef<any>) => {
    const {
        label,
        name,
        type = "text",
        containerClassName,
        required,
        placeholder,
        error,
        errorMessage,
        className,
        leftIcon,
        rightIcon,
        isTextarea,
        rows,
        disabled,
        ...rest
    } = props

    const [usedType, setUsedType] = useState(type)
    const togglePassword = () => {
        if (usedType === "password") setUsedType("text")
        else setUsedType("password")
    }
    return (
        <div className={`${containerClassName} mb-1 float-input`}>
            <div>
                {!isTextarea ?
                    <>
                        <input
                            className={`input-field input-app-design ${disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
                            ref={ref}
                            name={name}
                            type={usedType}
                            required={required}
                            disabled={disabled}
                            placeholder={placeholder ? placeholder : ' '}
                            {...rest}
                        />
                        {type === "password" &&
                            <span onClick={togglePassword} className="absolute right-3 top-2.5 cursor-pointer">
                                    {usedType === "password" ? <BsEye/> : <BsEyeSlash/>}
                                </span>
                        }
                        {leftIcon &&
                            <span className="absolute right-3 top-2.5 cursor-pointer"> {leftIcon} </span>
                        }
                    </>
                    :
                    <textarea
                        ref={ref}
                        {...rest}
                        name={name}
                        id={name}
                        rows={rows}
                        placeholder={placeholder ? placeholder : ' '}
                        className={`input-field input-app-design ${disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
                    />
                }
                <span className="span-label absolute p-3 text-xs">{label}</span>
            </div>
            <InputHelperMessage message={errorMessage} type="error" />
        </div>
    )
})

export default AppInput
