import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {Controller, useForm} from "react-hook-form";
import {AppButton, AppDatePicker, AppInput, AppSelect, Stepper, SwitchInput, TitleBar} from "../../components";
import {newJobSteps} from "../../constants/common";
import {
    UseGetCities,
    UseGetContactTypes,
    UseGetCountries,
    UseGetDomains,
    UseGetSalaryMethods,
    UseGetSubDomainsByDomainId
} from "../../services";
import {ItemType} from "../../types/CommonTypes";
import {useLocation, useNavigate} from "react-router-dom";
import routes from "../../constants/routes";

const JobsCreateStepOne = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const detailsData = state?.detailsData
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: "onSubmit", defaultValues: {
            title: detailsData?.title,
            contract_type_id: detailsData?.contract_type_id,
            publication_date: detailsData?.publication_date,
            expiration_date: detailsData?.expiration_date,
            subDomains:  detailsData?.subDomains,
            description: detailsData?.description,
            min_salary: detailsData?.min_salary,
            max_salary: detailsData?.max_salary,
            salary_method_id: detailsData?.salary_method_id,
            city: detailsData?.city,
            remote: detailsData?.remote,
        }
    });
    const [selectedCountry, setSelectedCountry] = useState<ItemType>(detailsData?.country)
    const [selectedDomain, setSelectedDomain] = useState<any>(detailsData?.domain_id)
    const selectSubDomainsRef: any = useRef();
    const selectCityRef: any = useRef();

    const {data: contractTypes, isLoading: isGettingContractTypes} = UseGetContactTypes()
    const {data: domains, isLoading: isGettingDomains} = UseGetDomains()
    const {data: salaryMethods, isLoading: isGettingSalaryMethods} = UseGetSalaryMethods()
    const {data: countries, isLoading: isCountriesLoading} = UseGetCountries()
    const {data: cities, isLoading: isCitiesLoading} = UseGetCities({countryName: selectedCountry?.country_name})
    const {
        data: subDomains,
        refetch: refetchSubDomains,
        isLoading: isGettingSubDomains,
        isRefetching: isRegettingSubDomains
    } = UseGetSubDomainsByDomainId(selectedDomain?.id)

    const onSubmit = (data: any) => {
        const body = {
            country: selectedCountry,
            domain_id: selectedDomain,
            ...data,
        }
        navigate(routes.jobCreateProfileSearched, {state: {...state, detailsData: body}})
    }

    return (
        <div>
            <TitleBar
                title="Créer une offre d'emploi"
                subTitle="Remplissez tous les champs requis, enregistrer progressivement et publier votre offre des qu'elle est prete"
            />
            <div className="grid grid-cols-4 px-5 py-5 gap-x-7">
                <div className="col-span-1">
                    <Stepper type="metromode" steps={newJobSteps} currentStep={1}/>
                </div>
                <motion.form
                    initial={{width: 0}}
                    animate={{width: "100%"}}
                    exit={{x: window.innerWidth, transition: {duration: 0.2}}}
                    onSubmit={handleSubmit(onSubmit)}
                    className="col-span-3">
                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-6 font-bold text-2xl">Détails de l'offre</h4>

                        <div className="col-span-2 mb-2">
                            <AppInput
                                {...register("title", {required: "Entrez le titre de l'offre"})}
                                label="Titre de l'offre"
                                type="text"
                                name="title"
                                error={!!(errors && errors["title"])}
                                errorMessage={errors["title"]?.message?.toString()}
                            />
                        </div>

                        <div className=" mb-2 col-span-2 grid grid-cols-3 gap-x-4">
                            <Controller
                                control={control}
                                name="contract_type_id"
                                rules={{
                                    required: {value: true, message: "Selectionnez le type de contrat"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Type de contrat"
                                        name="contract_type_id"
                                        text="name"
                                        value="id"
                                        selectedValue={detailsData?.contract_type_id?.name}
                                        isLoading={isGettingContractTypes}
                                        data={contractTypes?.data}
                                        error={!!(errors && errors["contract_type_id"])}
                                        errorMessage={errors["contract_type_id"]?.message?.toString()}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="publication_date"
                                rules={{
                                    required: {value: true, message: "Entrez la date de pblication"},
                                }}
                                render={({field}) => (
                                    <AppDatePicker
                                        {...field}
                                        defaultValue={field?.value}
                                        label="Date de publication"
                                        name="publication_date"
                                        errorMessage={errors["publication_date"]?.message?.toString()}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="expiration_date"
                                rules={{
                                    required: {value: true, message: "Entrez la date de cloture"},
                                }}
                                render={({field}) => (
                                    <AppDatePicker
                                        {...field}
                                        defaultValue={field?.value}
                                        label="Date de cloture"
                                        name="expiration_date"
                                        errorMessage={errors["expiration_date"]?.message?.toString()}
                                    />
                                )}
                            />

                        </div>

                        <div className="mb-2 col-span-2 grid grid-cols-2 gap-x-4">

                            <AppSelect
                                label="Domaine professionnel"
                                name="domain_id"
                                text="name"
                                value="id"
                                selectedValue={selectedDomain?.name}
                                isLoading={isGettingDomains}
                                onChange={(v: ItemType) => {
                                    setSelectedDomain(v)
                                    setTimeout(() => refetchSubDomains(), 500)
                                    selectSubDomainsRef?.current?.clearValue();
                                }}
                                data={domains?.data}
                            />

                            <Controller
                                control={control}
                                name="subDomains"
                                rules={{
                                    required: {value: true, message: "Selectionnez un ou plusieurs sous-domaines"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        ref={selectSubDomainsRef}
                                        label="Sous-domaines"
                                        name="subDomains"
                                        text="name"
                                        value="id"
                                        multiple
                                        placeholder={selectedDomain ? "Selectionner..." : "Selectionnez d'abord un domaine"}
                                        selectedValueMulti={detailsData?.subDomains}
                                        isLoading={isGettingSubDomains || isRegettingSubDomains}
                                        data={subDomains?.data}
                                        error={!!(errors && errors["subDomains"])}
                                        errorMessage={errors["subDomains"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>

                        <div className="mb-2 col-span-2">
                            <AppInput
                                {...register("description", {required: "Entrez la description de l'offre"})}
                                label="Discription de l'offre"
                                type="text"
                                name="description"
                                error={!!(errors && errors["description"])}
                                errorMessage={errors["description"]?.message?.toString()}
                                isTextarea
                                rows={5}
                            />
                        </div>

                        <div className="mb-2 col-span-2 grid grid-cols-3 gap-x-4">
                            <AppInput
                                {...register("min_salary")}
                                label="Salaire minimum"
                                type="text"
                                name="min_salary"
                            />
                            <AppInput
                                {...register("max_salary")}
                                label="Salaire maximum"
                                type="text"
                                name="max_salary"
                            />
                            <Controller
                                control={control}
                                name="salary_method_id"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Type de remuneration"
                                        name="salary_method_id"
                                        selectedValue={detailsData?.salary_method_id?.name}
                                        text="name"
                                        value="id"
                                        isLoading={isGettingSalaryMethods}
                                        data={salaryMethods?.data}
                                    />
                                )}
                            />
                        </div>

                        <div className="mb-2 col-span-2 grid grid-cols-3 gap-x-4">
                            <AppSelect
                                label="Pays"
                                name="country"
                                text="country_name"
                                value="country_name"
                                isLoading={isCountriesLoading}
                                selectedValue={selectedCountry?.country_name}
                                onChange={(v: ItemType) => {
                                    setSelectedCountry(v)
                                    selectCityRef?.current?.clearValue();
                                }}
                                data={countries}
                            />
                            <Controller
                                control={control}
                                name="city"
                                rules={{
                                    required: {value: true, message: "Selectionnez la ville"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        ref={selectCityRef}
                                        label="Ville"
                                        name="city"
                                        placeholder={selectedCountry ? "Selectionner..." : "Selectionner d'abord un pays"}
                                        isLoading={isCitiesLoading}
                                        selectedValue={detailsData?.city?.name}
                                        text="name"
                                        value="name"
                                        data={cities}
                                        error={!!(errors && errors["city"])}
                                        errorMessage={errors["city"]?.message?.toString()}
                                    />
                                )}
                            />

                            <div className="mt-4">
                                <Controller
                                    control={control}
                                    name="remote"
                                    render={({field}) => (
                                        <SwitchInput
                                            {...field}
                                            label="Travail à distance ?"
                                        />)
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className="text-center">
                        <AppButton
                            //isLoading={isSigningup}
                            size="md"
                            type="submit"
                            className="w-[50%] rounded-[50px] py-4"
                            bgColor="bg-primary-base hover:bg-primary-hover"
                            textColor="text-white"
                            label="Enregistrer et avancer"
                        />
                    </div>
                </motion.form>
            </div>
        </div>
    )
}

export default JobsCreateStepOne
