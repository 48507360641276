import Lottie from 'react-lottie';

type Props = {
    animationData: any
    width?: number
    height?:number
}

const LottieFile: React.FC<Props> = (props: Props) => {
    const {animationData, width = 250, height = 250} = props
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <Lottie
            options={defaultOptions}
            height={height}
            width={width}
        />
    )
}

export default LottieFile