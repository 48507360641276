import React, {forwardRef, LegacyRef} from "react";
import Flatpickr from "react-flatpickr";
import {BsCalendarWeek} from "react-icons/bs"

import "flatpickr/dist/flatpickr.css";
import moment from "moment/moment";
import {DateFormType} from "../../types/CommonTypes";
import InputHelperMessage from "./InputHelperMessage";

const AppDatePicker: React.FC<DateFormType> = forwardRef((props: DateFormType, ref: LegacyRef<any>) => {
    const {
        name,
        label,
        required,
        value,
        placeholder,
        defaultValue,
        errorMessage,
        onChange, ...rest} = props
    return (
        <div>
            <div className="relative">
                <Flatpickr
                    name={name}
                    ref={ref}
                    {...rest}
                    className="input-app-design placeholder:text-gray-400 "
                    defaultValue={defaultValue}
                    onChange={(v) => onChange(moment(v[0].toISOString()).format("DD-MM-YYYY"))}
                    options={{
                        dateFormat: "d-m-Y",
                        altFormat: "d-m-Y"
                    }}
                />
                <span className="absolute right-3 top-2.5 cursor-pointer"> <BsCalendarWeek /> </span>
                {label && <span className="absolute bg-white -top-2 left-3 text-xs font-bold px-3">{label}</span>}
            </div>
            <InputHelperMessage message={errorMessage} type="error" />
        </div>
    )
})

export default AppDatePicker
