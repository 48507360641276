import {createContext, useEffect, useState} from "react";
import {ThemeType} from "../types/CommonTypes";

type ThemeObj = { theme: ThemeType, setTheme:Function }

const getInitialTheme: () => ThemeType = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs  = window.localStorage.getItem('theme');
        if (storedPrefs && (storedPrefs === "dark" || storedPrefs === "light"))  return storedPrefs

        const userMedia = window.matchMedia('(prefers-color-scheme: light)');
        if (userMedia.matches)  return 'light'
    }
    return 'light' // light theme as the default;
};

export const ThemeContext = createContext<ThemeObj>({theme: getInitialTheme(), setTheme: () => {}})

const ThemeContextProvider: React.FC<any> = (props: any) => {
    const [theme, setTheme] = useState(getInitialTheme);

    const checkTheme: Function = (existing: ThemeType) => {
        const root = window.document.documentElement;
        const isDark = existing === 'dark';

        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(existing);

        localStorage.setItem('theme', existing);
    };

    if (props.initialTheme) checkTheme(props.initialTheme);

    useEffect(() => {
        checkTheme(theme);
    }, [theme]);

    const value: ThemeObj = {theme, setTheme}
    return (
        <ThemeContext.Provider value={value}>
            {props.children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider
