import axios, {AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders} from "axios"
import axiosRetry from "axios-retry";
import {ToastNotif} from "../helpers";
import {AppStorage} from "../utils";

const { REACT_APP_BASE_URL, REACT_APP_CSRF_URL } = process.env
const lang = AppStorage.getItem("i18nextLng") || "en"

let headers: AxiosRequestHeaders = {
    "Content-Type": "application/json",
    "lang": lang,
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    headers,
    timeout: 30000,
    withCredentials: true,
})

axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
});

axiosInstance.interceptors.request.use(
    config =>  config,
    error =>  Promise.reject('Erreur --> ' + error)
)

axiosInstance.interceptors.response.use(
    res => res,
    err => {
        if(!err.response) {
            ToastNotif.simple("Connexion error", "error")
            return false
        }
        if(err.response.data && err.response.data.description && err.response.data.description === "Unauthenticated"){
            ToastNotif.simple("Please, session expired login again.", "error")
            AppStorage.clearAll()
            window.location.href = "/login"
            return false
        }
        return err.response
    }
)

const post = async  (url:string, data?:any, config?: AxiosRequestConfig<any>) => {
    await  axios.get(""+REACT_APP_CSRF_URL, {
        headers: {
            'lang': lang
        }
    })
    return await axiosInstance.post(url, data, config)
}

const put = async  (url:string, data?:any, config?: AxiosRequestConfig<any>) => {
    await  axios.get(""+REACT_APP_CSRF_URL, {
        headers: {
            'lang': lang
        }
    })
    return await axiosInstance.put(url, data, config)
}

const _delete = async  (url:string, config?: AxiosRequestConfig<any>) => {
    await  axios.get(""+REACT_APP_CSRF_URL, {
        headers: {
            'lang': lang
        }
    })
    return await axiosInstance.delete(url, config)
}

const patch = async  (url:string, data?:any, config?: AxiosRequestConfig<any>) => {
    await  axios.get(""+REACT_APP_CSRF_URL, {
        headers: {
            'lang': lang
        }
    })
    return await axiosInstance.patch(url, data, config)
}

const methods = {
    get: axiosInstance.get,
    post,
    put,
    patch,
    delete: _delete
}

export default methods
