import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {BsPlusCircle} from "react-icons/bs"
import {useNavigate} from "react-router-dom";
import {
    AppButton,
    AppInput,
    EmptyState,
    JobListItemPreloader,
    ListItemBadge,
    TitleBar
} from "../../components";
import routes from "../../constants/routes";
import {UseGetJobs} from "../../services";
import JobListItem from "./JobListItem";
import {useTranslation} from "react-i18next";
import {BiSearchAlt} from "react-icons/bi";
import {RiDraftLine} from "react-icons/ri";
import {BsCircleFill, BsArchiveFill, BsCalendar2Check} from "react-icons/bs";

const JobsList = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const [jobs, setJobs] = useState<{}[]>([])
    const {register, watch} = useForm({ mode: "all" });

    const leftMenus = [
        {icon: <RiDraftLine className="text-gray-600" />, title: "Offres en brouillon", badgeValue: "2"},
        {icon: <BsCircleFill className="text-green-600" />, title: "Offres en ligne", badgeValue: "4"},
        {icon: <BsArchiveFill className="text-primary-base" />, title: "Offres archivées", badgeValue: "1"},
        {icon: <BsCalendar2Check className="text-red-600" />, title: "Offres cloturées", badgeValue: "1"},
    ]

    const {data: jobsData, isLoading: isGettingJobs, refetch: refetchJobs } = UseGetJobs()

    useEffect(() => {
        if(!isGettingJobs && jobsData?.data){
            setJobs(jobsData.data)
        }
    }, [isGettingJobs, jobsData])

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if(jobsData?.data && name === "searchWord"){
                const temp: {}[] = jobsData.data.filter((item:any)=> item.title.toLowerCase().includes(value.searchWord))
                setJobs(temp)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, jobsData])

    const onActionClick = () => {
        refetchJobs()
    }

    return (
        <>
            <TitleBar
                title="Liste des offres"
                subTitle="Créer, publier et gerer toutes vos offres d'emploi"
                children={
                <div>
                    <AppButton
                        onClick={() => navigate(routes.jobCreateOfferDetails)}
                        category="bloc"
                        size="sm"
                        label="Créer une offre"
                        icon={<BsPlusCircle />}
                    />
                </div>
                }
            />

            <div className="p-5">

                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-3">
                        <div className="mb-8 grid grid-cols-4">
                            <div className="col-span-1">
                                <AppInput
                                    {...register("searchWord")}
                                    label="Rechercher..."
                                    name="searchWord"
                                    required
                                    leftIcon={<BiSearchAlt className="text-gray-700 dark:text-gray-300"/>}
                                />
                            </div>
                        </div>
                        <div>
                            {jobs.length ?
                                (<div>
                                    {jobs.map((job:any, index) => (
                                        <div key={index}>
                                            <JobListItem onActionClick={onActionClick} job={job} />
                                        </div>
                                    ))}
                                </div>) : null}

                            {isGettingJobs && !jobs.length ? <div>
                                {[1,2,3,4].map(item =>  <JobListItemPreloader key={item} />)}
                            </div>: null}

                            {!isGettingJobs && !jobs.length ? <div className=""><EmptyState
                                title="Aucune offre trouvée !"
                                description="Créez une offre d'emploi et elle apparaittra ici."
                            /></div>: null}
                        </div>
                    </div>

                    <div  className="col-span-1 pl-5">
                        <h3 className="text-sm font-semibold">APERCU TOTAL</h3>
                        <div className="mt-4">
                            {leftMenus.map((item, index) => <ListItemBadge key={index} title={item.title} icon={item.icon} badgeValue={item.badgeValue}/> ) }
                        </div>
                    </div>

                </div>

            </div>




        </>
    )
}

export default JobsList
