import {useMutation, useQuery} from "react-query";
import {FormatUrlParams, GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type QueryParams = {
    status?: string
}

export const UseGetCompanySizes = (options?: QueryParams) =>
    useQuery(GetQueryKey("companySize", options), async () => https.get(`${endpoints.companySizes}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseEditCompany = () =>
    useMutation(async (body: {[key: string]: any }) => https.patch(`${endpoints.configureCompany}/${body.company_id}`, body));
