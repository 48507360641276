export const replaceAll = (str: string | number | undefined, old: string, n: string = "") => {
    return str ? str?.toString().split(old).join(n || "") : ""
}


//Change DD/MM/YYYY to YYYY/MM/DD
export const ReverseDate = (date: string | Date, f?:"fr" | "en") => {
    const format = f ? f : "en"
    if(!date) return ""
    if(format === "fr") {
        const [year, month, day] = date.toString().split('-');
        return [day, month, year].join('-');
    }
    const [day, month, year] = date.toString().split('-');
    return [year, month, day].join('-');
}

export const FormatUrlParams = (params?: {[key: string]: any}) => {
    if(!params) return ""
    let url = ""
    Object.keys(params).map((item, index) => {
        if(index === 0) url = `?${item}=${params[item] || ""}`
        else url += `&${item}=${params[item] || ""}`
        return item
    })
    return url
}

export const GetQueryKey = (key: string, params?: {[key: string]: any}) => {
    if(!params) return [key]
    const options = Object.values(params).map(item => item)
    return [key, ...options]
}

export const TrailingText = (str: string, limit: number = 20) => {
    return str.length > limit ? `${str.slice(0, limit)}...` :  str;
}
