export const assetsUrl = process.env.REACT_APP_ASSETS_URL
export const dateOptions: {} = {  year: 'numeric', month: '2-digit', day: '2-digit'};
export const dateTimeOptions: {} = {  year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };

export const applyDocumentFormats = [
    {id: "image", name: "image"},
    {id: "document", name: "document"}
]

export const cacheTime = {
    long: 9990000,
    medium: 60000,
    short: 1000,
}

export const localStorageKeys = {
    token: "JzudWIi9OiIxMjM0NTY3ODkwFtZSI6I",
    user: "iOiJIUzI1NiI4sInR5cCI6IkpXVC23s",
    permissions: "QT4fwpMeJf36POk6yJtehaWgdEWWbdg",
    company: "IkpvaG4gRG9lICO20kpurjEYBSi394D",
    access: "0Ikp4fjshwpMeJO11kwujEYBSgsuGSJ",
}

export const newJobSteps = [
    {title: "Details de l'offre", description: "Titre, spécifications, validité, etc."},
    {title: "Profil recherché", description: "Toutes les exigences liées au meilleur profil recherché"},
    {title: "Processus de recrutement", description: "Comment se déroulera chaque étape lors du recrutement"},
    {title: "Documents", description: "Joindre tous les fichiers nécessaires"}
]
