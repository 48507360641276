const routes = {
    home: "/",
    login: "/login",
    signUp: "/sign-up/personal-information",
    signUp2: "/sign-up/company-information",
    invitationSignup: "/invitation-signup",
    profile: "/profile",
    mailValidated: "/mail-validated",
    tokenExpired: "/token-expired",
    mailAlreadyValidated: "/mail-already-validated",
    jobs: "/jobs",
    jobNew: "/jobs/new",
    jobCreateOfferDetails: "/jobs/create/offer-details",
    jobCreateProfileSearched: "/jobs/create/sought-profile",
    jobCreateRecruitmentProcess: "/jobs/create/recruitment-process",
    jobCreateUploadDocuments: "/jobs/create/upload-documents",
    jobEdit: "/jobs/edit",
}

export default routes
