import {useEffect} from "react";
import {BlockingModal, LottieFile, Spinner} from "../../components";
import emailCheck from "../../assets/lotties/email-check.json";
import {auth, UseGetCurrentUser, UseResendValidationLink} from "../../services";
import {ToastNotif} from "../../helpers";

const EmailChecking = () => {
    const connectedUser = auth.getCurrentUser()
    const {
        refetch: resendValidationLink,
        isRefetching: isResendingAgain,
        isLoading: isResending,
        data: resendData
    } = UseResendValidationLink()
    const {
        refetch: getCurrentUser,
        isRefetching: isGettingUserAgain,
        isLoading: isGettingUser,
        data: userData
    } = UseGetCurrentUser()

    useEffect(() => {
        if (resendData && !isResendingAgain) {
            if (resendData.error) {
                ToastNotif.simple(resendData.description, "error")
            } else ToastNotif.simple("Le lien de validation vous a été renvoyé avec succès !", "success")
        }
    }, [resendData, isResendingAgain])

    useEffect(() => {
        if (userData && !isGettingUserAgain) {
            console.log(userData)
            if (userData?.data && userData?.data?.user?.hasVerifiedEmail) {
                ToastNotif.simple("Adresse email validée avec succès", "success")
                auth.updateCurrentUser(userData.data.user)
                window.location.reload()
            } else ToastNotif.simple("Veuillez vous assurer que vous avez validé votre adresse email.", "error")
        }
    }, [userData, isGettingUserAgain])


    return (
        <>
            {connectedUser && !connectedUser.hasVerifiedEmail && <BlockingModal
                title="Valider votre adresse email"
                description="Un message vous a été dans votre adresse email, vérifier dans votre boîte de réception, et cliquez sur le lien pour valider votre adresse email. Si vous n'avez recu de message, vérifiez vos spams ou envoyez à nouveau le lien."
                validateLabel={isGettingUserAgain || isGettingUser ? <Spinner/> : "Oui, J'ai validé !"}
                cancelLabel={isResending || isResendingAgain ? <Spinner/> : "Renvoyez le lien"}
                onCancel={resendValidationLink}
                onValidate={getCurrentUser}
                icon={<LottieFile animationData={emailCheck} width={100} height={100}/>}
            />}
        </>
    )
}

export default EmailChecking
