import React, {useContext} from "react";
import {BiSearchAlt} from "react-icons/bi";
import {IoNotifications} from "react-icons/io5";
import {BsBuilding, BsBoxArrowLeft, BsFillFilePersonFill, BsEnvelope} from "react-icons/bs";
import {useMutation} from "react-query";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {ToggleLanguage, ToggleTheme} from "../../helpers";
import {images} from "../../assets/images";
import {AvatarProfile, Dropdown, OverLoader} from "../index";
import {UserContext} from "../../contexts/UserContext";
import {auth} from "../../services";
import routes from "../../constants/routes";


const HeaderBar = () => {
    const {currentUser: {user: connectedUser, company: connectedCompany}} = useContext(UserContext);
    const {isLoading, mutate} = useMutation(async () => await auth.logout())
    const {t} = useTranslation();
    const menus = [
        [
            {
                type: "default",
                title: <AvatarProfile
                    avatar={connectedUser && connectedUser.avatar ? connectedUser.avatar : images.defaultAvatar}
                    title={connectedUser ? connectedUser.name : ""}
                    subTitle={connectedUser ? connectedUser.post : ""}/>
            },
        ],
        [
            {
                type: "custom",
                menuItem: {icon: <BsFillFilePersonFill/>, link: routes.profile, title: i18next.t("common.profile")}
            },
            {
                type: "custom",
                menuItem: {icon: <BsBuilding/>, link: routes.profile, title: i18next.t("common.company_details")}
            },
        ],
        [
            {type: "custom", menuItem: {icon: <BsBoxArrowLeft/>, onClick: mutate, title: i18next.t("common.logout")}}
        ]
    ]

    return (
        <>
            {isLoading && <OverLoader/>}
            <div
                className="h-12 w-full bg-slate-200 dark:bg-slate-900 sticky top-0 z-50  flex justify-between items-center px-5">
                <div>
                    <div
                        className="px-3 bg-white dark:bg-slate-600 dark:text-gray-200 py-1.5 rounded-md font-bold flex justify-center items-center">
                        <BsBuilding/> <span className="ml-2">{connectedCompany && connectedCompany.company_name}</span>
                    </div>
                </div>
                <div>
                    <div className="bg-white dark:bg-gray-800 p-2 flex justify-center items-center rounded-full">
                        <BiSearchAlt className="text-gray-700 dark:text-gray-300"/>
                        <input type="text" placeholder={t("common.search")}
                               className="outline-none focus:w-[300px] w-[200px] text-sm h-full bg-transparent transition-all duration-500 appearance-none px-2"/>
                    </div>
                </div>
                <div className="flex items-center gap-x-4 justify-center">
                    <span className="flex relative cursor-pointer">
                        <BsEnvelope size={20} className="text-gray-400 relative inline-flex"/>
                        <span style={{fontSize: 9}} className="absolute left-2 -top-1 text-white p-2 w-2 h-2 justify-center items-center inline-flex rounded-full bg-red-500">
                            1
                        </span>
                    </span>
                    <span className="flex relative cursor-pointer">
                        <span className="animate-ping transition-all duration-200 absolute right-1 -top-1 h-1/3 w-1/3 inline-flex rounded-full bg-red-500 opacity-75" />
                        <IoNotifications size={20} className="text-gray-400 relative inline-flex"/>
                    </span>
                    <span className=""><ToggleTheme/></span>
                    <span className="pr-3 border-r border-white"><ToggleLanguage/></span>
                    <div className="cursor-pointer">
                        <Dropdown
                            title="Options"
                            position="right"
                            menus={menus}
                            type="custom"
                            titleComponent={<img
                                src={connectedUser && connectedUser.avatar ? connectedUser.avatar : images.defaultAvatar}
                                className="rounded-full shadow-md border border-white w-9 "
                                alt="user avatar"/>}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderBar
