import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {AppButton, RecruitmentStep, RecruitmentStepPreloader, Stepper, TitleBar} from "../../components";
import {newJobSteps} from "../../constants/common";
import {useLocation, useNavigate} from "react-router-dom";
import routes from "../../constants/routes";
import {UseGetRecruitmentStepActions, UseGetRecruitmentSteps} from "../../services";
import {RecruitmentAction, RecruitmentStepItem} from "../../types/CommonTypes";

const JobsCreateStepThree = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const profileData = state?.profileData
    const processData = state?.processData
    if (!profileData) window.location.href = routes.jobCreateProfileSearched
    const [actions, setActions] = useState<RecruitmentAction[]>([])
    const [formData, setFormData] = useState(processData?.steps)

    const {data: recruitmentSteps, isLoading: isGettingRecruitmentSteps} = UseGetRecruitmentSteps({fields: "id,title,description,flag_color", sortDirection: "ASC"})
    const {data: recruitmentStepActions, isLoading: isGettingStepActions} = UseGetRecruitmentStepActions({fields: "id,title,slug,description"})

    useEffect(() => {
        if(recruitmentStepActions){
            const temp = recruitmentStepActions?.data?.map((item: any) => {
                return {id: item.id, title: item.title, description: item.description, content: item.description, isSelected: false, data: null}
            })
            setActions(temp)
        }
    }, [recruitmentStepActions])

    const getReturnedActions = (id: number, data: any) => {
        setFormData({
            ...formData,
            [id]: data,
        });
    }

    const onNext = () => {
        if(formData && Object.keys(formData).length){
            const recruitmentSteps = Object.keys(formData)
            let recruitmentStepActions: any[][]  = []
            let recruitmentStepActionValues: any[][] = []
            for (let i = 0; i < recruitmentSteps.length; i++) {
                const stepId = recruitmentSteps[i]
                recruitmentStepActions[i] = []
                recruitmentStepActionValues[i] = []
                for (let j = 0; j < formData[stepId].length; j++) {
                    const item: RecruitmentAction = formData[stepId][j]
                    recruitmentStepActions[i][j] = item.isSelected ? item.id.toString() : null
                    recruitmentStepActionValues[i][j] = item.isSelected && item.data ? item.data : null
                }
            }
            const body = {
                steps: formData,
                recruitmentSteps,
                recruitmentStepActions,
                recruitmentStepActionValues,
            }
            navigate(routes.jobCreateUploadDocuments, {state: {...state, processData: body}})
        }
    }

    return (
        <div>
            <TitleBar
                title="Créer une offre d'emploi"
                subTitle="Remplissez tous les champs requis, enregistrer progressivement et publier votre offre des qu'elle est prete"
            />
            <div className="grid grid-cols-4 px-5 py-5 gap-x-7">
                <div className="col-span-1">
                    <Stepper type="metromode" steps={newJobSteps} currentStep={3}/>
                </div>
                <motion.div className="col-span-3"
                            initial={{width: 0}}
                            animate={{width: "100%"}}
                            exit={{x: window.innerWidth, transition: {duration: 0.2}}}
                >
                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-0 font-bold text-lg">Processus de recrutement</h4>
                        <p className="text-xs mb-4 text-gray-500">Définissez comment se déroulera votre processus de récrutement</p>
                        <div className="mt-4">
                            {actions?.length && recruitmentSteps && recruitmentStepActions ? recruitmentSteps?.data?.map((step: RecruitmentStepItem, index: number) =>
                                    <RecruitmentStep
                                        defaultValues={processData?.steps && processData.steps[step?.id] ? processData.steps[step?.id] : {}}
                                        actions={actions}
                                        onReturn={getReturnedActions}
                                        item={step}
                                        key={index}
                                        isExpended={!!(processData?.steps && processData.steps[step?.id])}
                                    />) : null }

                            {isGettingRecruitmentSteps ? <div>
                                {[1, 2, 3, 4].map(item => <RecruitmentStepPreloader key={item}/>)}
                            </div> : null}
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <AppButton
                            size="md"
                            type="submit"
                            className="w-[30%] rounded-[50px] py-4"
                            bgColor="bg-white border hover:border-gray-600 hover:bg-gray-600"
                            textColor="text-gray-600 hover:text-white"
                            label="Précédent"
                            onClick={() => navigate(routes.jobCreateProfileSearched, {state: {...state}})}
                        />

                        <AppButton
                            size="md"
                            onClick={onNext}
                            className="w-[50%] rounded-[50px] py-4"
                            bgColor="bg-primary-base hover:bg-primary-hover"
                            textColor="text-white"
                            label="Enregistrer et avancer"
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default JobsCreateStepThree
