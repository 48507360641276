import {useQuery} from "react-query";
import {GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

export const UseGetSubDomainsByDomainId = (domainId?: string) =>
    useQuery(
        GetQueryKey("subDomainsByDomainID"),
        async () => https.get(`${endpoints.subDomainsByDomain}/${domainId}`),
        {staleTime: cacheTime.short, enabled: false});
