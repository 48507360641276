import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {BsShieldExclamation} from 'react-icons/bs'
import {AppButton} from "../index";

type Props = {
    title?: string | React.ReactNode
    description?: React.ReactNode
    icon?: React.ReactNode
    isOpen?: boolean,
    onValidate?: Function
    validateLabel?: string | React.ReactNode
    onCancel?: Function
    cancelLabel?: string | React.ReactNode
}
const BlockingModal: React.FC<Props> = (props: Props) => {
    const {
        isOpen = true,
        onValidate,
        onCancel,
        title,
        description,
        icon,
        validateLabel = "Yes, Validated",
        cancelLabel = "Cancel"
    } = props
    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed inset-0 bg-gray-500 dark:bg-gray-700 dark:bg-opacity-40 bg-opacity-40 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div
                        className="flex relative items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white dark:bg-gray-800 rounded-lg border-t-4 border-t-primary-base text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col items-center">
                                        {icon ? icon : <div
                                            className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <BsShieldExclamation className="h-6 w-6 text-red-600" aria-hidden="true"/>
                                        </div>
                                        }
                                        <div className="mt-3 pt-5  text-center sm:mt-0 sm:ml-4">
                                            {title && <Dialog.Title as="h3"
                                                                    className="text-lg leading-6 font-medium text-gray-900">
                                                {title}
                                            </Dialog.Title>}
                                            <div className="mt-2">
                                                {description}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <AppButton
                                        onClick={onValidate}
                                        category="bloc"
                                        size="sm"
                                        label={validateLabel}
                                    />
                                    {onCancel && <div className="mr-3">
                                        <AppButton
                                            onClick={onCancel}
                                            category="bloc"
                                            size="sm"
                                            label={cancelLabel}
                                            textColor="text-gray-700"
                                            bgColor="bg-white hover:bg-gray-300 hover:text-gray-700 border-gray-300"
                                        />
                                    </div>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default BlockingModal
