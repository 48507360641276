import React from "react";

type Props = {
    avatar: string
    title?: string
    subTitle?: string
}

const AvatarProfile: React.FC<Props> = (props: Props) => {
    const {avatar, title, subTitle} = props
    return(
        <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
                <img
                    className="h-10 w-10 rounded-full"
                    src={avatar}
                    alt=""
                />
            </div>
            <div className="ml-3 flex-1">
                {title && <p className="text-sm font-medium text-gray-900 dark:text-white">{title}</p>}
                {subTitle && <p className="text-sm text-gray-500">{subTitle}</p>}
            </div>
        </div>
    )
}
export default AvatarProfile