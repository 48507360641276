import {useContext} from "react";
import {ThemeContext} from "../contexts/ThemeContext";
import {MdOutlineLightMode, MdDarkMode} from "react-icons/md";


const ToggleTheme: React.FC = () => {
    const {theme, setTheme} = useContext(ThemeContext);
    return (
        <div className="cursor-pointer">
            {theme === "dark" ?
                <span className="text-gray-400"
                      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
                    <MdOutlineLightMode size={20}/>
                </span> :
                <span className="text-gray-400"
                      onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
                    <MdDarkMode size={20}/>
                </span>
            }
        </div>
    )
}

export default ToggleTheme