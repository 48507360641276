import React, {useContext, useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
    AppInput,
    AppRadioBox,
    AppSelect,
    BlockingOverlay,
    OverLoader,
    StepperControl, TagsInput, ThreeDotsLoader
} from "../../components";
import {StepperContext} from "../../contexts/StepperContext";
import {UserContext} from "../../contexts/UserContext";
import {ToastNotif} from "../../helpers";
import {
    auth,
    https,
    UseEditCompany,
    UseEditStaff,
    UseGetCompanySizes,
    UseGetDomains, UseSendStaffInvitation
} from "../../services";
import endpoints from "../../constants/endpoints";
import {ItemType} from "../../types/CommonTypes";
import regex from "../../constants/regex";


type StepType = {
    steps?: any
    currentStep: number
    onClick: Function
    onConfirm?: Function
}

const PositionForm = ({steps, currentStep, onClick}: StepType) => {
    const {currentUser: {user: connectedUser}} = useContext(UserContext)
    const {stepData, setStepData} = useContext(StepperContext)
    const {currentUser: {company}} = useContext(UserContext)
    const [newStep, setNewStep] = useState<number>()
    const formRef = useRef(null)
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onSubmit", defaultValues: {post: stepData.post || connectedUser?.post}
    });

    const {mutate: editStaff, data: editData, isLoading: isEditing} = UseEditStaff()

    useEffect(() => {
        if (editData && !editData.error && connectedUser) {
            auth.updateCurrentUser({...connectedUser, post: stepData.post})
            onClick(newStep)
        }
    }, [editData])

    const onNext = () => {
        (formRef.current as any).dispatchEvent(new Event("submit", {bubbles: true, cancelable: true}));
    }

    const onSubmit = (data: any) => {
        setStepData({...stepData, ...data})
        editStaff({id: connectedUser?.staff_id, post: data.post})
    }

    return (
        <>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="my-3">
                    <h3 className="font-medium text-md">Quel poste occupez-vous chez {company?.company_name} ?</h3>
                    <p className="text-xs text-gray-500 pb-5">Cela nous aidera à rendre l'application plus adaptée à vos
                        besoins.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="mb-[15px] col-span-2">
                    <AppInput
                        {...register("post", {required: "Entrez votre prénom"})}
                        label="Votre fonction"
                        type="text"
                        name="post"
                        error={!!(errors && errors["post"])}
                        errorMessage={errors["post"]?.message?.toString()}
                    />
                </form>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 grid grid-cols-2 place-items-center">
                <div></div>
                <StepperControl
                    buttonSize="sm"
                    onConfirm={() => {
                    }}
                    onClick={(n: number) => {
                        setNewStep(n)
                        onNext()
                    }}
                    isClicking={isEditing}
                    steps={steps}
                    currentStep={currentStep}/>
            </div>
        </>
    )
}

const CompanySize = ({steps, currentStep, onClick}: StepType) => {
    const {stepData, setStepData} = useContext(StepperContext)
    const {control, handleSubmit} = useForm({
        mode: "onSubmit", defaultValues: {company_size: stepData.company_size}
    });
    const formRef = useRef(null)
    const [newStep, setNewStep] = useState<number>()

    const {data: sizes, isLoading: isGettingSizes} = UseGetCompanySizes()

    const onNext = () => {
        (formRef.current as any).dispatchEvent(new Event("submit", {bubbles: true, cancelable: true}));
    }

    const onSubmit = () => {
        if (newStep && currentStep > parseInt(newStep.toString())) {
            onClick(newStep)
            return
        }
        if (Object.keys(stepData).length > 1) onClick(newStep)
        else ToastNotif.simple("Veuillez indiquer la taille de votre entreprise", "error")
    }

    return (
        <>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="my-3">
                    <h3 className="font-medium text-md">Quelle est la taille de votre entreprise ?</h3>
                    <p className="text-xs text-gray-500 pb-5">Cela nous aidera à bien comprendre vos besoins en matière
                        de recrutement.</p>
                </div>
                {isGettingSizes ? <ThreeDotsLoader/> :
                    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                        <Controller
                            control={control}
                            name="company_size"
                            render={({field}) => {
                                return (
                                    <>
                                        {sizes && sizes.data.map((item: any, index: number) => (
                                            <div key={index} className="bg-slate-200 my-2 p-2 rounded-md">
                                                <AppRadioBox
                                                    {...field}
                                                    id={item.id}
                                                    onClick={(v: any) => setStepData({...stepData, company_size: v})}
                                                    selected={item.id.toString() === stepData.company_size}
                                                    value={item.id}
                                                    label={item.name}
                                                />
                                            </div>))}
                                    </>
                                )
                            }}
                        />

                    </form>}
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 grid grid-cols-2 place-items-center">
                <div></div>
                <StepperControl
                    buttonSize="sm"
                    onConfirm={() => {
                    }}
                    onClick={(n: number) => {
                        setNewStep(n)
                        setTimeout(() => onNext(), 300)
                    }}
                    steps={steps}
                    currentStep={currentStep}/>
            </div>
        </>
    )
}

const CompanyDomain = ({steps, currentStep, onClick}: StepType) => {
    const {currentUser: {company: connectedCompany}} = useContext(UserContext)
    const {stepData, setStepData} = useContext(StepperContext)
    const [selectedDomain, setSelectedDomain] = useState(stepData?.domain)
    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    const {data: domains, isLoading: isGettingDomains} = UseGetDomains()
    const {mutate: editCompany, data: editData} = UseEditCompany()

    useEffect(() => {
        if (editData) {
            if (!editData.error && connectedCompany && stepData.domain && stepData.company_size) auth.updateCurrentCompany({
                ...connectedCompany,
                domain_id: stepData.domain,
                company_size: stepData.company_size
            })
            else ToastNotif.simple(editData.description, "error")
        }
    }, [editData])

    const onChange = (v: ItemType) => {
        setStepData({...stepData, domain: v})
        setIsEmpty(false)
        setSelectedDomain(v)
    }

    const onNext = (newStep: number) => {
        setIsEmpty(false)
        if (newStep === 2) onClick(newStep)
        else {
            if (Object.keys(stepData).length > 2) {
                editCompany({
                    company_id: connectedCompany?.company_id,
                    domain_id: stepData.domain.id,
                    company_size_id: stepData.company_size
                })
                onClick(newStep)
            } else setIsEmpty(true)
        }
    }

    return (
        <>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-[300px]">
                <div className="my-3">
                    <h3 className="font-medium text-md">Dans quel domaine votre entreprise oeuvre-t-elle ?</h3>
                    <p className="text-xs text-gray-500 pb-5">Cela nous aidera à identifier correctement les différents
                        demandeurs d'emploi.</p>
                </div>
                {isGettingDomains ? <ThreeDotsLoader/> :
                    <div className="mb-[15px] col-span-2">
                        <AppSelect
                            name="domain"
                            label="Domaine"
                            isLoading={isGettingDomains}
                            selectedValue={selectedDomain?.name}
                            onChange={onChange}
                            data={domains?.data}
                            error={isEmpty}
                            errorMessage={isEmpty ? "Sélectionner le domaine de votre entreprise" : ""}
                        />
                    </div>
                }
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 grid grid-cols-2 place-items-center">
                <div></div>
                <StepperControl
                    buttonSize="sm"
                    onConfirm={() => {
                    }}
                    onClick={onNext}
                    steps={steps}
                    currentStep={currentStep}
                />
            </div>
        </>
    )
}

const InviteStaff = ({steps, currentStep}: StepType) => {
    const {currentUser: {company: connectedCompany}} = useContext(UserContext)
    const {setStepData} = useContext(StepperContext)
    const [showError, setShowError] = useState<boolean>(false)
    const [isCheckingEmail, setIsCheckingEmail] = useState<boolean>(false)
    const [errors, setErrors] = useState<{ email: string, error: string }[]>([])
    const [emailList, setEmailList] = useState<any[]>([])

    const {mutate: sendInvitation, isLoading: isSendingInvitation} = UseSendStaffInvitation()

    const closeModal = () => {
        setStepData([])
        auth.getCurrentCompany()
        if (connectedCompany) auth.updateCurrentCompany({...connectedCompany, isConfigured: true})
        window.location.href = "/"
    }

    const checkEmails = async () => {
        let err: { email: string, error: string }[] = []
        for (let i = 0; i < emailList.length; i++) {
            const req = await https.get(`${endpoints.checkInvitationValidity}/${emailList[i].label}`)
            if (req.error) err.push({email: emailList[i].label, error: req.description})
        }
        // emailList.forEach(async (email) => {
        //     const req = await https.get(`${endpoints.checkInvitationValidity}/${email.label}`)
        //     if (req.error) err.push({email: email.label, error: req.description})
        // })
        //console.log("///", err)
        return err
    }

    const onConfirm = async () => {
        if (!emailList.length) {
            ToastNotif.simple("Veuillez saisir une adresse email et appuyer sur ENTER pour l'ajouter", "error")
            return
        }
        setShowError(false)
        setIsCheckingEmail(true)
        const checkingErrors = await checkEmails()
        if (checkingErrors.length) {
            setShowError(true)
            setErrors(checkingErrors)
            setIsCheckingEmail(false)
            return false
        }
        setIsCheckingEmail(false)
        if (connectedCompany) sendInvitation({company_id: connectedCompany?.company_id, emails: emailList})
        closeModal()
    }

    return (
        <>
            {(isSendingInvitation || isCheckingEmail) &&
                <OverLoader/>
            }
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="my-3">
                    <h3 className="font-medium text-md">Qui peut utiliser Darketix avec vous ?</h3>
                    <p className="text-xs text-gray-500 pb-5">Invitez vos collègues</p>
                </div>
                {showError && <ul className="mb-8 text-xs text-red-700 list-disc ml-5">
                    {errors.map((item, index: number) => <li key={index}>{item.error}: <span
                        className="font-bold">{item.email}</span></li>)}
                </ul>}
                <div className="mb-[15px] col-span-2">
                    <TagsInput
                        label="Adresses email"
                        placeholder="Entrer une adresse email et appuyer sur ENTER pour l'ajouter"
                        onEnterPress={(v) => setEmailList(v)}
                        isMatching={(v: string) => !!v.match(regex.email)}
                    />
                </div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 grid grid-cols-2 place-items-center">
                <div></div>
                <StepperControl
                    backButtonLabel="Plus tard"
                    nextButtonLabel="Envoyer"
                    buttonSize="sm"
                    onConfirm={onConfirm}
                    onClick={closeModal}
                    steps={steps}
                    currentStep={currentStep}
                />
            </div>
        </>
    )
}

const Configuration = () => {
    const steps = [
        {title: "Post Form"},
        {title: "Company domain"},
        {title: "Company size"},
        {title: "Invite staff"}
    ]
    const [currentStep, setCurrentStep] = useState(1)
    const [confData, setConfData] = useState<any>([])

    const handleClick = (newStep: number) => setCurrentStep(newStep)

    const finalRender = (step: number) => {
        switch (step) {
            case (2) :
                return <CompanySize
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            case (3) :
                return <CompanyDomain
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            case (4) :
                return <InviteStaff
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            default:
                return <PositionForm
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
        }
    }

    return (
        <BlockingOverlay
            children={
                <div
                    className="relative bg-white rounded-lg border-t-4 border-t-primary-base text-left overflow-hidden shadow-xl transform transition-all sm:my-8  w-1/3">
                    <StepperContext.Provider value={{stepData: confData, setStepData: setConfData}}>
                        {finalRender(currentStep)}
                    </StepperContext.Provider>
                </div>

            }
        />

    )
}

export default Configuration
