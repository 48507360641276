import React from "react";
import {useNavigate} from "react-router-dom";
import {AppButton, BlockingOverlay, LottieFile} from "../../components";
import partyPopper from "../../assets/lotties/party-popper.json";
import {images} from "../../assets/images";

const EmailValidated = () => {
    const navigate = useNavigate()
    return (
        <div className="w-screen h-screen flex justify-center items-center lg:px-60 px-4 py-30 bg-primary-base">
            <BlockingOverlay
                bgColor=""
                children={
                <div className="md:w-1/2 w-full">
                    <p className="flex justify-center">
                        <img src={images.logoWhite} alt="logo Darketix" className="md:w-1/4 w-1/2"/>
                    </p>
                    <div
                        className="relative bg-white rounded-lg border-t-4 border-t-gray-900 text-left overflow-hidden shadow-xl transform transition-all my-8 ">
                        <div className="bg-white px-4 pt-5 pb-10">
                            <h3 className="text-center my-7 text-2xl font-bold text-slate-800">Bravo !</h3>
                            <LottieFile animationData={partyPopper} width={100} height={100}/>
                            <div className="flex flex-col justify-center items-center my-7">
                               <p className=" text-slate-600 text-center mb-5">
                                   Your email address has been validated <span className="text-green-700 font-bold">successfully !</span>
                                   <br/> Now you have full access to the application<br/> features.
                               </p>
                                <AppButton size="sm" onClick={() => navigate("/login")}
                                           //color="primary-outline"
                                           label="Access your account" />
                            </div>

                        </div>
                    </div>
                </div>
                }
            />
        </div>
    )
}

export default EmailValidated
