import React, {createContext, useState} from "react";
import {auth} from "../services";
import {Company, User} from "../types/UserTypes";

type UserCtxType = {
    token: string | null
    user: User | null
    permissions: any
    company: Company | null
}
type Props = {
    currentUser: UserCtxType
    setCurrentUser: Function
}

export const UserContext = createContext<Props>({
    currentUser: {
        token: null,
        user: null,
        permissions: null,
        company: null
    },
    setCurrentUser: () => {}
})

const getCurrentUser = () => {
    const token = auth.getAuthToken()
    const user = auth.getCurrentUser()
    const company = auth.getCurrentCompany()
    return {token, user, permissions: null, company}
}

const UserContextProvider: React.FC<any> = (props: any) => {
    const [currentUser, setCurrentUser] = useState<UserCtxType>(getCurrentUser())

    const value: Props = {currentUser, setCurrentUser}

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;