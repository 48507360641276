import React from "react";
import {useQuery} from "react-query";
import {BsPlusCircle, BsThreeDotsVertical, BsCalendarEvent} from "react-icons/bs"
import {AiOutlineFundProjectionScreen} from "react-icons/ai"
import {https} from "../../services";
import {AppButton, ThreeDotsLoader} from "../../components";
import {FlagSquare} from "../../components/recruitment-steps/RecruitmentStep";
import {RecruitmentStepItem} from "../../types/CommonTypes";
import {images} from "../../assets/images";
import endpoints from "../../constants/endpoints";

const JobRecruitment = () => {

    const {isLoading: isStepsLoading, data: recuitStepData} = useQuery(["recruitmentSteps"], async () => {
        const res = await https.get(`${endpoints.recruitmentSteps}?fields=id,title,description,flag_color&sortDirection=ASC`)
        return res.data
    }, {staleTime: 120000})
    const recruitmentSteps: [] = recuitStepData || []

    const CandidateCard = () => {
        return (
            <div className="flex flex-row gap-2 border border-gray-300 py-3 px-2 rounded mb-2 shadow shadow-slate-300 bg-white cursor-pointer">
                <div><img src={images.defaultAvatar} alt="avatar" className="w-7 rounded-full"/></div>
                <div>
                    <p className="text-xs font-semibold">John Nzasi Mayele</p>
                    <div>
                        <span className="text-green-500"><AiOutlineFundProjectionScreen className="inline-block" /> <span className="text-xs font-bold">98%</span> </span>
                        <span className="ml-3"><BsCalendarEvent className="inline-block text-xs" /> <span className="text-xs"> 17/01/2022</span> </span>
                    </div>
                </div>
            </div>
        )
    }

    const RecruitmentColum = ({step}: {step: RecruitmentStepItem}) => {
        return (
            <div className="col-span-auto h-screen bg-slate-200 p-4 rounded">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row items-center gap-2">
                        <FlagSquare color={step.flag_color} />
                        <div className="font-bold text-sm">{step.title}</div>
                    </div>
                   <div>
                       <span className="cursor-pointer"><BsThreeDotsVertical /></span>
                   </div>
                </div>
                <div className="mt-4">
                    {[1,2,3].map(item => <CandidateCard key={item} />)}
                </div>
            </div>
        )
    }

    return(
        <div className="p-5">
            {isStepsLoading ? <ThreeDotsLoader /> :
                (
                    <div>
                        <div className="mb-4 flex flex-row justify-between">
                            <div></div>
                            <div>
                                <AppButton
                                    label="Add a candidate"
                                    category="bloc"
                                    size="sm"
                                    icon={<BsPlusCircle />}
                                />
                            </div>
                        </div>
                        <div className="grid grid-flow-col gap-4">
                            {recruitmentSteps.map((step: any, index) => <RecruitmentColum step={step} key={index} />)}
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default JobRecruitment
