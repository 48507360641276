import React from "react";

type InputMsgType = {
    message?: string,
    type?: "error" | "success" | "default"
}

type renderType = {
    message?: string
}

const RenderDefault: React.FC<renderType> = ({message}: renderType) => {
    return (
        <div className="font-medium text-xs mt-1">{message}</div>
    )
}

const RenderSuccess: React.FC<renderType> = ({message}: renderType) => {
    return (
        <div className="font-medium text-xs mt-1 text-green-700">{message}</div>
    )
}

const RenderError: React.FC<renderType> = ({message}: renderType) => {
    return (
        message ? <div className="font-light text-[10px] mt-1 text-red-500">{message}</div> :
            <div className="font-light text-[10px] mt-1 text-red-500">&nbsp;</div>
    )
}

const InputHelperMessage: React.FC<InputMsgType> = (props: InputMsgType) => {
    const {message, type = "default"} = props
    if (type === 'error') return <RenderError message={message}/>
    if (type === 'success') return <RenderSuccess message={message}/>
    return <RenderDefault message={message}/>
}

export default InputHelperMessage
