import {forwardRef, LegacyRef} from 'react'
import {Switch} from '@headlessui/react'

type Props = {
    label?: string
    onChange: () => void
    value: boolean
    [key: string]: any
}

const SwitchInput: React.FC<Props> = forwardRef((props: Props, ref: any) => {
    const {onChange, value: isEnable, label, ...rest} = props
    //const [enabled, setEnabled] = useState(false)

    return (
        <Switch.Group>
            <div className="flex">
                <Switch.Label>
                    {label && <label className="block mb-1 text-sm font-bold text-gray-700 mr-1">{label}</label>}
                </Switch.Label>
                <Switch
                    {...rest}
                    ref={ref}
                    //checked={isEnable}
                    onChange={onChange}
                    className={`${
                        isEnable ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full `}
                >
                    <span
                        className={`transform transition ease-in-out duration-200 ${
                            isEnable ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white`}
                    />
                </Switch>
            </div>
        </Switch.Group>
    )
})

export default SwitchInput
