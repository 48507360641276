import React from "react";
import {RiDraftLine} from "react-icons/ri";
import {BsArchiveFill, BsCalendar2Check, BsCircleFill} from "react-icons/bs";

const JobStatus = ({status}: { status: string }) => {
    let render = <div className="font-bold text-slate-900"><RiDraftLine className="inline align-top mr-1 text-sm"/> Brouillon</div>
    if(status === "2") render = (<div className="text-green-600 font-bold">
        <BsCircleFill className="inline align-top mr-1"/> En ligne
    </div>)
    if(status === "5") render = (<div className="text-slate-500 font-bold">
        <BsArchiveFill className="inline align-top mr-1"/> Archivé
    </div>)
    if(status === "3") render = (<div className="text-red-600 font-bold">
        <BsCalendar2Check className="inline align-top mr-1"/> Cloturé
    </div>)

    return render
}

export default JobStatus
