import {IoNewspaperOutline} from "react-icons/io5"
import {HiStatusOnline} from "react-icons/hi"
import {BsArchive, BsCalendar2Check, BsLaptop} from "react-icons/bs"
import {AppButton, GreatNumberCard, LineChart} from "../../components";


export default function Home() {
    return (
        <div className="p-5">

            <div className="grid grid-cols-6 gap-4">
                <div className="col-span-2">
                    <div className="border max-h-[150px] overflow-y-scroll border-primary-base rounded flex flex-row items-center justify-between px-4 py-3 gap-4">
                        <div>
                            <div className="mb-3">
                                <h3 className="text-[10px] cursor-pointer font-bold text-slate-800">10% des candidats sur Darketix bossent dure !</h3>
                                <p className="text-[9px] text-slate-500">Lorem ipsum dolor sit amet, consectetur one soluta!</p>
                            </div>
                            <div className="mb-3">
                                <h3 className="text-[10px] cursor-pointer font-bold text-slate-800">5% demamndent une formation en anglais</h3>
                                <p className="text-[9px] text-slate-500">Lorem ipsum dolor sit amet, consectetur one soluta sit amet, consectetur one soluta</p>
                            </div>

                            <AppButton
                                label="En savoir +"
                                category="bloc"
                                size="xs"
                                //color="primary"
                            />
                        </div>
                        <div><IoNewspaperOutline className="text-[100px] text-primary-base" /></div>
                    </div>
                </div>
                <div className="col-span-4">
                    <div className="border h-[150px] rounded bg-slate-200 px-4 py-3">
                        <div className="flex flex-row justify-between items-center">
                            <h3 className="text-sm font-bold">Aperçu des offres</h3>
                            <p className="text-slate-500 text-[10px] font-semibold">30 derniers jours</p>
                        </div>
                        <div className="flex flex-row justify-between items-center mt-9">
                            <div className="flex flex-row items-center gap-4">
                                <div className="p-3 bg-green-200 flex flex-row justify-center items-center rounded-full">
                                    <HiStatusOnline className="text-green-700 text-xl" />
                                </div>
                                <div>
                                    <h4 className="font-bold text-md">200</h4>
                                    <p className="text-[9px] text-slate-500">Offres publiées</p>
                                </div>
                            </div>

                            <div className="flex flex-row items-center gap-4">
                                <div className="p-3 bg-yellow-200 flex flex-row justify-center items-center rounded-full">
                                    <BsLaptop className="text-yellow-700 text-xl" />
                                </div>
                                <div>
                                    <h4 className="font-bold text-md">104</h4>
                                    <p className="text-[9px] text-slate-500">Offres en cours</p>
                                </div>
                            </div>

                            <div className="flex flex-row items-center gap-4">
                                <div className="p-3 bg-blue-200 flex flex-row justify-center items-center rounded-full">
                                    <BsArchive className="text-blue-700 text-xl" />
                                </div>
                                <div>
                                    <h4 className="font-bold text-md">4</h4>
                                    <p className="text-[9px] text-slate-500">Offres archivées</p>
                                </div>
                            </div>

                            <div className="flex flex-row items-center gap-4">
                                <div className="p-3 bg-red-200 flex flex-row justify-center items-center rounded-full">
                                    <BsCalendar2Check className="text-red-700 text-xl" />
                                </div>
                                <div>
                                    <h4 className="font-bold text-md">43</h4>
                                    <p className="text-[9px] text-slate-500">Offres cloturées</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-6 gap-4 mt-4">
                <div className="col-span-1">
                    <GreatNumberCard
                        rightTitle="7 derniers jours"
                        amount="80"
                        subTitle="Postulants"
                    />
                </div>
                <div className="col-span-1">
                    <GreatNumberCard
                        rightTitle="7 derniers jours"
                        amount="1 210"
                        subTitle="Postulants"
                    />
                </div>
                <div className="col-span-2"></div>
                <div className="col-span-2"></div>
            </div>

        </div>
    )
}
