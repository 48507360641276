import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {To, useNavigate, useLocation, Link} from "react-router-dom";
import {GoHome, GoGear} from "react-icons/go"
import {VscGraph} from "react-icons/vsc"
import {FaRegUserCircle} from "react-icons/fa"
import {TiGroup} from "react-icons/ti"
import {FiActivity} from "react-icons/fi"
import {BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronDown, BsChevronRight, BsDot} from "react-icons/bs";
import {IoDocumentAttachOutline, IoTrailSignOutline, IoCalendarOutline} from "react-icons/io5"
import {images} from "../../assets/images";
import {SideBarMenuType} from "../../types/CommonTypes";

const SideBar: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation();
    const defaultMenus: SideBarMenuType[] = [
        {title: "home", link: "/", icon: <GoHome size={22}/>, active: true},
        {title: "jobs", link: "/jobs", icon: <IoDocumentAttachOutline size={22}/>,},
        {title: "candidates", link: "/candidates", icon: <TiGroup size={22}/>},
        {title: "assessment", link: "/assessment", icon: <IoTrailSignOutline size={22}/>, separator: true},

        {title: "planner", link: "/planner", icon: <IoCalendarOutline size={22}/>},
        {title: "activities", link: "/activities", icon: <FiActivity size={22}/>},
        {title: "account", link: "/account", icon: <FaRegUserCircle size={22}/>},
        {title: "reports", link: "/reports", icon: <VscGraph size={22}/>},
    ]
    const [menus, setMenus] = useState(defaultMenus)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isSubOpen, setIsSubOpen] = useState<string[]>(Array.from("false".repeat(menus.length)))

    useEffect(() => {
        setMenus(prev => {
            return prev.map((item) => {
                if (location.pathname === "/" &&  location.pathname === item.link ) return {...item, active: true}
                else if (location.pathname.includes(item.link.toString()) && item.link !== "/" ) return {...item, active: true}
                else return {...item, active: false}
            })
        })
        return () => {
        }
    }, [location])

    const onClick = (index: number) => {
        if (menus[index].submenus) {
            setIsSubOpen(prev => {
                let temp = [...prev]
                temp[index] = temp[index] === "true" ? "false" : "true"
                return temp
            })
            setIsOpen(true)
        } else navigate(menus[index].link)

        setMenus(prev => {
            return prev.map((item, i) => {
                if (i === index) return {...item, active: true}
                else return {...item, active: false}
            })
        })
    }

    const onSubMenuClick = (link: To) => navigate(link)

    return (
        <div
            className={`${isOpen ? "w-64" : "w-20"} sticky top-0  h-screen  bg-primary-base relative transition-all duration-500 ease-in-out`}>
            <span onClick={() => setIsOpen(!isOpen)}
                  className="absolute cursor-pointer rounded-full  -right-3 bottom-1/2 w-7 h-7 bg-white border-2 border-primary-base flex items-center justify-center">
                 <BsChevronDoubleLeft className={`${!isOpen && "hidden"}`} size={10}/>
                <BsChevronDoubleRight className={`${isOpen && "hidden"}`} size={10}/>
            </span>
            <div className="transition-all duration-500 ease-in-out px-5 pt-2 pb-3">
                <Link to="/">
                    <img src={images.logoWhite} className={`${!isOpen && "hidden"}`} alt="Logo Darketix"/>
                    <img src={images.smallLogoWhite} className={`${isOpen && "hidden"}`} alt="Logo Darketix"/>
                </Link>
            </div>

            <ul className="pt-6 px-5">
                {
                    menus.map((item, index: number) => (
                        <React.Fragment key={index}>
                            <li
                                title={isOpen ? "" : item.title}
                                onClick={() => {
                                    onClick(index)
                                }}
                                className={`${item.active ? "bg-secondary text-primary-base" : "text-gray-300"} ${item.separator ? "mb-10" : "mb-1"} p-2 text-sm flex justify-between items-center cursor-pointer hover:bg-slate-100 rounded-md hover:text-primary-base transition-all duration-200 ease-in-out`}>
                            <span className="flex gap-x-4 items-center justify-start">
                                {item.icon}
                                <span className={`${!isOpen && "hidden"} origin-left duration-200`}>{t(`common.${item.title}`)}</span>
                            </span>
                                {item.submenus && <span className={`${!isOpen && "hidden"} cursor-pointer`}>
                                    {isSubOpen[index] === "true" ? <BsChevronDown/> : <BsChevronRight/>}
                                </span>}
                            </li>
                            {isOpen && item.submenus && isSubOpen[index] === "true" &&
                                <ul className="pb-2 transition-all duration-500">
                                    {item.submenus.map((submenu, i) => (
                                        <li key={i} onClick={() => {
                                            onSubMenuClick(submenu.link)
                                        }}
                                            className="flex justify-start items-center py-2 pl-6 text-sm text-slate-200 hover:bg-slate-100 rounded-md hover:text-primary-base transition-all duration-500 cursor-pointer">
                                            <BsDot/> <span className="pl-3">{submenu.title}</span>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </React.Fragment>
                    ))
                }
            </ul>

            <div className="px-5 absolute bottom-4 w-full">
                <div
                    className={`${!isOpen && "animate-spin hover:animate-none"} p-2 text-gray-300 text-sm flex justify-between items-center cursor-pointer hover:bg-slate-100 rounded-md hover:text-primary-base transition-all duration-500 ease-in-out`}>
                    <span className="flex gap-x-4 items-center justify-start">
                        <GoGear size={22} className=""/>
                        <span className={`${!isOpen && "hidden"} origin-left duration-200`}>Parameters</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SideBar