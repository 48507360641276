import {KeyboardEventHandler, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import InputHelperMessage from "./InputHelperMessage";

const components = {
    DropdownIndicator: null,
};

interface Option {
    readonly label: string;
    readonly value: string;
}

type PropsType = {
    onEnterPress: (value: any[]) => void,
    placeholder?: string
    label?: string
    errorMessage?: string
    isMatching?: (newValue: string) => boolean
}

const createOption = (label: string) => ({
    label,
    value: label,
});

const TagsInput = (props: PropsType) => {
    const {onEnterPress, placeholder, label, errorMessage, isMatching} = props
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<readonly Option[]>([]);
    const [internError, setInterError] = useState<string>("")

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInterError("")
                if(isMatching && !isMatching(inputValue))  {
                    setInterError("Valeur non valide")
                    return;
                }
                const isExisted = value.filter(i => i.label === inputValue)
                if (isExisted?.length) {
                    setInterError("Valeur déja existante")
                    return;
                }
                setValue((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
                onEnterPress([...value, createOption(inputValue)])
        }
    };

    //console.log(internError)

    return (
        <div className="mb-1 relative">
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => {
                    setValue(newValue)
                    onEnterPress([...newValue])
                }}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder ? placeholder : "Type something and press enter..."}
                value={value}
                className="border border-slate-400 rounded-md outline-none"
                styles={
                    {
                        placeholder: (provided, state) => ({
                            ...provided,
                            fontSize: 13
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            fontWeight: "normal",
                            fontSize: 13,
                            border: "none"
                        }),
                    }
                }
            />
            {label && <span className="absolute bg-white -top-2 left-3 text-xs font-bold px-3">{label}</span>}
            <InputHelperMessage message={errorMessage || internError} type="error"/>
        </div>
    );
};

export default TagsInput
