import {forwardRef, LegacyRef} from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {InputFormType} from "../../types/CommonTypes";
import RequiredMark from "./RequiredMark";
import InputHelperMessage from "./InputHelperMessage";

const AppPhoneInput: React.FC<InputFormType> = forwardRef((props: InputFormType, ref: LegacyRef<any>) => {
    const {name, required, label, error, errorMessage, disabled, ...rest} = props
    return (
        <div className="float-input mb-1">
            <div>
                <PhoneInput
                    {...rest}
                    specialLabel={label}
                    country={'cd'}
                    inputClass={`input-field input-app-design  ${disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
                    inputProps={{name, required}}
                    enableSearch
                    placeholder=" "
                    inputStyle={{width: '100%', fontSize: 13, paddingTop: 12.5, paddingBottom: 10, outline: 'none', borderColor: "#94a3b8"}}
                />
            </div>
            <InputHelperMessage message={errorMessage} type="error" />
        </div>
    )
})

export default AppPhoneInput
