import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {BsCloudUpload} from "react-icons/bs"
import {IoDocumentAttachOutline} from "react-icons/io5"

function FileDropZone() {
    const [files, setFiles] = useState<{[key:string] : any}[]>([]);
    const {getRootProps, getInputProps, open} = useDropzone({
        maxFiles: 2,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div  key={file.name}>
            <div className="mr-2 mb-2 border p-1 rounded">
                {file.type.includes("image") ? <img
                    className="w-20"
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }}
                /> : <div className="w-20 py-7 items-center justify-center flex flex-row">
                    <IoDocumentAttachOutline className="text-[50px] text-gray-500" />
                </div>}

                {file.size}
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <section className="border border-dashed text-center rounded p-2">
            <div {...getRootProps({className: 'dropzone'})} onClick={open} className="bg-gray-100 py-10 border cursor-pointer rounded ">
                <input {...getInputProps()} />
                <div className="items-center flex flex-col" >
                    <BsCloudUpload className="text-[50px]  text-gray-500" />
                    <p className="text-xs mt-4 text-gray-500">Drag 'n' drop some files here, or click to select files</p>
                </div>

            </div>
            <aside className="mt-4 flex flex-row">
                {thumbs}
            </aside>
        </section>
    );
}

export default FileDropZone
