import {useQuery} from "react-query";
import {FormatUrlParams, GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type QueryParams = {
    sortDirection?: "ASC" | "DESC"
    fields?: string
}


export const UseGetRecruitmentSteps = (options?: QueryParams) =>
    useQuery(GetQueryKey("recruitmentSteps", options), async () => https.get(`${endpoints.recruitmentSteps}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseGetRecruitmentStepActions = (options?: QueryParams) =>
    useQuery(GetQueryKey("recruitmentStepActions", options), async () => https.get(`${endpoints.recruitmentStepActions}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});
