const urls =  {
    appUrl: 'https://recruiter.darketix.com/',
    //appUrl: 'http://localhost:3000/',
    logout: '/logout',
    login: '/recruiters/login',
    signup: '/recruiters/signup',
    domains: "/domains",
    subDomains: "/sub-domains",
    subDomainsByDomain: "/sub-domains/domains",

    companySizes: "/company-sizes",
    configureCompany: "/companies/configure",
    companyExists: "/companies/exists/",
    jobs: "/companies/jobs",
    changeJobStatus: "/companies/jobs/change-status/",

    sendRecruiterInvitation: "/recruiters/send-invitations",
    checkInvitationValidity: "/recruiters/check-if-invitation-email-is-not-valid",
    editStaff: "/recruiters/staffs/patch",
    currentUser: "/recruiters/auth/me",
    sentEmailValidationLink: "/email/verification-notification",
    checkUserExist: "/users/exists",

    salaryMethods: "/salary-methods",
    jobDomains:"/job-domains",
    contractTypes:"/contract-types",
    experienceLevels:"/experience-levels",
    studyLevels:"/study-levels",
    domainFormations: "/domain-formations",
    languages: "/languages",
    skills: "/skills",
    softSkills: "/skills/softs",
    techSkills: "/skills/technicals",
    recruitmentSteps: "/recruitment-steps",
    recruitmentStepActions: "/recruitment-step-actions",

}

export default urls
