import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {HiUser, HiKey} from "react-icons/hi"
import {AppButton, AppInput} from "../../components";
import {auth} from "../../services";
import {assetsUrl} from "../../constants/common";
import routes from "../../constants/routes";
import {images} from "../../assets/images";
import {FcGoogle} from "react-icons/fc";
import {Link} from "react-router-dom";
import {ToastNotif} from "../../helpers";

export default function Login() {
    const {t} = useTranslation()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "onSubmit"});

    const onSubmit = async (data: any) => {
        setIsSubmitting(true)
        const res = await auth.login(data)
        if(!res || res.error) {
            if(res.message) ToastNotif.simple(res.description, "error")
        } else {
            window.location.href = "/"
        }
        setIsSubmitting(false)
    }

    return (
        <div
            style={{
                backgroundImage: `url("${assetsUrl}/bg/abstract-bg.jpg")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            className="w-screen h-screen flex justify-center items-center bg-white">

            <div className="grid grid-cols-1 md:grid-cols-2 h-[520px] w-[1000px] rounded-xl shadow-2xl bg-white">
                <div className="bg-primary-base rounded-tl-xl  rounded-bl-xl">
                    <p className="pt-8 pl-8"><img src={images.logoWhite} className="w-1/2" alt="Logo Darketix"/></p>
                </div>
                <div className="flex flex-col justify-center px-10">
                    <div className="relative">
                        <p className="text-right absolute right-0 -top-8">
                            <Link to={routes.signUp} className="text-primary-base ml-2 font-bold hover:text-gray-700"> Inscrivez-vous !</Link>
                        </p>
                        <div className="mb-5">
                            <h2 className="text-gray-800 text-3xl font-bold">Connexion</h2>
                            <p className="text-gray-500 text-xs">Recrutez les meilleurs pour votre entreprise.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <AppInput
                                {...register("email", {required: "Entrez votre adresse email"})}
                                label="Email"
                                type="email"
                                name="email"
                                error={!!(errors && errors["email"])}
                                errorMessage={errors["email"]?.message?.toString()}
                                rightIcon={<HiUser/>}
                            />
                            <AppInput
                                {...register("password", {required: "Entrez votre mot de passe"})}
                                label="Mot de passe"
                                name="password"
                                type="password"
                                error={!!(errors && errors["password"])}
                                errorMessage={errors["password"]?.message?.toString()}
                                containerClassName="mt-2"
                                rightIcon={<HiKey/>}
                            />
                            <p className="text-right -mt-4">
                                <Link to={routes.signUp} className="text-primary-base text-xs">
                                    Mot de passe oublié ?
                                </Link>
                            </p>

                            <div className="mt-5">
                                <AppButton
                                    isLoading={isSubmitting}
                                    size="md"
                                    type="submit"
                                    className="w-[100%] py-4 rounded-[100px]"
                                    bgColor="bg-primary-base hover:bg-primary-hover"
                                    textColor="text-white"
                                    onClick={() =>{}}
                                    label="Connexion"
                                />
                            </div>

                            <div className="border-b text-center mt-4">
                                <div className="relative top-3 left-[38.5%] bg-white px-3 w-20 text-center text-gray-500">OU</div>
                            </div>

                            <div className="mt-10">
                                <AppButton
                                    size="md"
                                    isButton={false}
                                    bgColor="bg-white border hover:border-gray-600 rounded-3xl"
                                    textColor="text-gray-600"
                                    onClick={() =>{}}
                                    icon={<FcGoogle />}
                                    label="Google"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
