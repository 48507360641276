import React, {useEffect, useRef, useState} from "react";

type StepProps = {
    item: any,
    index: number

}

type StepperProps = {
    steps: any[]
    currentStep: number
    type?: "default" | "metromode"
}

const DefaultStep: React.FC<StepProps> = (props: StepProps) => {
    const {index, item} = props
    return (
        <div className={`${index !== 0 && "ml-1"} w-full`}>
            <p className={`${item.completed ? "text-primary-base " : "text-gray-400 "} text-sm mb-1 font-bold`}>{index + 1}. {item.title}</p>
            <div
                className={`${item.completed && "border-primary-base "} border-t-2 border-4 rounded transition duration-500 ease-in-out`}/>
        </div>
    )
}

const MetroModeStep: React.FC<StepProps> = (props: StepProps) => {
    const {index, item} = props
    return (
            <div className="flex gap-x-6 items-center">
                <div className={`${item.completed ? "bg-blue-500 text-white" :"bg-blue-100 text-blue-500"} py-2 px-4 h-full flex justify-center rounded-lg font-bold`}>
                    {index + 1}
                </div>
                <div>
                    <h4 className="font-bold text-gray-700">{item.title}</h4>
                    {item.description && <p className="text-xs text-slate-500">{item.description}</p>}
                </div>
            </div>
    )
}

const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
    const {steps, currentStep, type = "default"} = props

    const [newStep, setNewStep] = useState<any>([])
    const stepRef = useRef<any>()

    const updateStep = (stepNumber: number, steps: any[]) => {
        const newSteps = [...steps]
        let count = 0
        while (count < newSteps.length) {
            //current
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true,
                }
                count++
            }
            //completed
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true,
                }
                count++
            }
            //pending
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false,
                }
                count++
            }
        }
        return newSteps
    }

    useEffect(() => {
        const stepsState = steps.map((step, index) => Object.assign({}, {
                ...step,
                completed: false,
                highlighted: index === 0,
                selected: index === 0,
            })
        )

        stepRef.current = stepsState
        const current = updateStep(currentStep - 1, stepRef.current)
        setNewStep(current)
    }, [steps, currentStep])

    return (
        <>
            {
                type === "default" ?
                    <div className="flex flex-row justify-between items-center">
                            {newStep.map((item: any, index: number) => <DefaultStep item={item} key={index} index={index}/>)}
                    </div> :
                    (newStep.map((item: any, index: number) =>  (
                        <React.Fragment  key={index}>
                            <MetroModeStep item={item} index={index} />
                            {index < newStep.length-1 && <div className="py-5 border-l border-l-2 border-gray-300 border-dotted ml-5"/>}
                        </React.Fragment>))
                    )
            }
        </>
    )
}

export default Stepper
