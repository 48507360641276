import {AppButton} from "../index";
import {ButtonSize} from "../../types/CommonTypes";

type Props = {
    onClick: Function
    isClicking?: boolean
    isConfirming?:  boolean
    onConfirm?: Function
    steps: any
    currentStep: number
    disabled?: boolean
    buttonSize?: ButtonSize
    nextButtonLabel?: string | React.ReactNode
    backButtonLabel?: string | React.ReactNode
}
const StepperControl = ({
                            steps,
                            currentStep,
                            onClick,
                            onConfirm,
                            disabled = false,
                            buttonSize = "md",
                            nextButtonLabel,
                            backButtonLabel,
                            isClicking,
                            isConfirming
                        }: Props) => {

    const handleClick = (direction: string) => {
        let newStep = direction === 'next' ? currentStep + 1 : currentStep - 1
        if (newStep > 0 && newStep <= steps.length) onClick(newStep, direction)
    }

    return (
        <div className="container flex justify-around">
            {currentStep !== 1 &&
                <AppButton
                    size={buttonSize}
                    className="w-1/2 mr-1"
                    textColor="text-gray-700"
                    bgColor="bg-white hover:bg-gray-300 hover:text-gray-700 border-gray-300"
                    label={backButtonLabel ? backButtonLabel : "Retour"}
                    onClick={() => handleClick("back")}
                    disabled={currentStep === 1}/>}
            {currentStep === steps.length ?
                <AppButton
                    isLoading={isConfirming}
                    size={buttonSize}
                    disabled={disabled}
                    className={`${currentStep === 1 ? "w-full" : "w-1/2 ml-1"}`}
                    //color="primary"
                    label={nextButtonLabel ? nextButtonLabel : "Valider"}
                    onClick={onConfirm ? () => onConfirm() : () => {
                    }}
                />
                :
                <AppButton
                    isLoading={isClicking}
                    size={buttonSize}
                    disabled={disabled}
                    className={`${currentStep === 1 ? "w-full" : "w-1/2 ml-1"}`}
                    //color="primary"
                    label={nextButtonLabel ? nextButtonLabel : "Suivant"}
                    onClick={() => handleClick("next")}
                />
            }
        </div>
    )
}

export default StepperControl
