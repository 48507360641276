import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import parse from 'html-react-parser';
import {https} from "../../services";
import {AppButton, HorizontalLinePreloader, TitleBar} from "../../components";
import React, {useState} from "react";
import {FiMapPin} from "react-icons/fi";
import {FiEdit3} from "react-icons/fi"
import {BsCalendarWeek, BsCalendar2Check, BsTools} from "react-icons/bs";
import {FaFileContract, FaGraduationCap} from "react-icons/fa"
import {ImProfile} from "react-icons/im"
import moment from "moment/moment";
import JobStatus from "./JobStatus";
import JobRecruitment from "./JobRecruitment";
import routes from "../../constants/routes";
import commonFunctions from "../../utils/CommonFunctions";
import endpoints from "../../constants/endpoints";


type InfoProps = {
    icon: React.ReactNode
    title: string
    description: string
}

const DetailInfo = (props: InfoProps) => {
    return (
        <div className="flex flex-row mb-3">
            {props.icon}
            <div className="ml-3">
                <span className="text-xs text-slate-500 block">{props.title}</span>
               <p className="font-semibold text-xs -mt-0.5">{props.description}</p>
            </div>
        </div>
    )
}

const BadgeInfo = ({title, bg}:{title: string, bg?: string}) => {
    return (
        <div className={`${bg ? bg: 'text-blue-600'} text-xs bg-blue-100  py-1 px-2 mb-1 mr-1 rounded inline-block`}>{title}</div>
    )
}

const JobsDetail = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const {isLoading, data, refetch} = useQuery(["job", params.id], async () => {
        const res = await https.get(`${endpoints.jobs}/${params.id}?relations=domain:id,name;jobStatus:id,name;experienceLevel:id,name;contractType:id,name;studyLevel:id,name;company:id,company_name;salaryMethod:id,name;staff:id,name;user:id,email;domainFormations:id,name;domainJobs:id,name;subDomains:id,name;primaryLanguages:id,name;secondaryLanguages:id,name;softSkills:id,name;technicalSkills:id,name;jobApplyFormQuestions:id,title;recruitmentStepActions;recruitmentStepActions.recruitmentStepAction;jobApplyFormDocuments:id,title,description,type`)
        return res.data
    }, {staleTime: 30000})
    const job = data || null

    const headerSubMenus = [
        {
            isActive: activeIndex === 0,
            title: "Details",
        },
        {
            isActive:  activeIndex === 1,
            title: "Recrutement",
        },
        {
            isActive:  activeIndex === 2,
            title: "Fichiers",
        },
        {
            isActive:  activeIndex === 3,
            title: "Activités",
        },
        {
            isActive:  activeIndex === 4,
            title: "Rapports",
        },

    ]

    const onHeaderSubMenuClick = (index: number) => {
        setActiveIndex(index)
    }

    const DetailsRender = () => {
        return(
           <>
               {job ? (
                   <div className="p-5 grid grid-cols-4 gap-5">
                       <div className="col-span-3">
                           <div className="col-span-3">
                               <div className="bg-white rounded px-4 pt-5 pb-2 grid grid-cols-3">
                                   <div className="col-span-1">
                                       <DetailInfo
                                           icon={<BsCalendarWeek className="text-2xl text-slate-500" />}
                                           title="Date de publication"
                                           description={moment(job.created_at).format('lll')}
                                       />
                                       <DetailInfo
                                           icon={<BsCalendar2Check className="text-2xl text-slate-500" />}
                                           title="Date de cloture"
                                           description={moment(job.created_at).format('lll')}
                                       />
                                   </div>
                                   <div className="col-span-1">
                                       <DetailInfo
                                           icon={<FaFileContract className="text-2xl text-slate-500" />}
                                           title="Type de contrat"
                                           description={job.contract_type.name}
                                       />
                                       <DetailInfo
                                           icon={<BsTools className="text-2xl text-slate-500" />}
                                           title="Domaine professionnel"
                                           description={job.domain.name}
                                       />

                                   </div>
                                   <div className="col-span-1">
                                       <DetailInfo
                                           icon={<ImProfile className="text-2xl text-slate-500" />}
                                           title="Niveau professionnel"
                                           description={job.experience_level.name}
                                       />
                                       <DetailInfo
                                           icon={<FaGraduationCap className="text-2xl text-slate-500" />}
                                           title="Niveau d'étude"
                                           description={job.study_level.name}
                                       />
                                   </div>
                               </div>
                               <div className="text-sm my-5 pb-2 border-b font-bold">Description</div>
                               <div className="text-sm mt-5">{parse(commonFunctions.breakLine(job.description))}</div>
                           </div>
                       </div>

                       <div className="col-span-1 bg-white rounded p-4">
                           <div>
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Domaine d'études</div>
                               {job.domain_formations ?
                                   (<div>
                                       {job.domain_formations.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucun domaine d'études</div>
                               }
                           </div>

                           <div className="mt-4">
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Sous-domaines professionnels</div>
                               {job.sub_domains ?
                                   (<div>
                                       {job.sub_domains.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucun sous-domaine professionnel</div>
                               }
                           </div>

                           <div className="mt-4">
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Compétences techniques</div>
                               {job.technical_skills ?
                                   (<div>
                                       {job.technical_skills.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucune compétence technique</div>
                               }
                           </div>

                           <div className="mt-4">
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Compétences générales</div>
                               {job.soft_skills ?
                                   (<div>
                                       {job.soft_skills.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucune compétence générale</div>
                               }
                           </div>

                           <div className="mt-4">
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Langues principales</div>
                               {job.primary_languages ?
                                   (<div>
                                       {job.primary_languages.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucune langue principale</div>
                               }
                           </div>

                           <div className="mt-4">
                               <div className="text-sm mb-3 pb-2 border-b font-bold">Langues secondaires</div>
                               {job.secondary_languages ?
                                   (<div>
                                       {job.secondary_languages.map((item: {name: string}, index: number) => <BadgeInfo key={index} title={item.name} /> )}
                                   </div>) :
                                   <div>Aucune langue secondaire</div>
                               }
                           </div>
                       </div>

                   </div>
               ): null}
           </>
        )
    }

    const finalRender =  () => {
        switch (activeIndex) {
            case (1) : return <JobRecruitment />
            case (2) : return <> </>
            case (3) : return <> </>
            case (4) : return <> </>
            default: return <DetailsRender />
        }

    }

    return (
        <>
            <TitleBar
                title={isLoading ? <HorizontalLinePreloader containerClass="h-5" height={17} /> : job.title}
                children={
                    <div>
                        <AppButton
                            onClick={() => navigate(`${routes.jobEdit}/${job.id}`)}
                            //color="primary-outline"
                            category="bloc"
                            size="sm"
                            label="Edit this offer"
                            icon={<FiEdit3 />}
                        />
                    </div>
                }
                subTitle={
                    isLoading ?  <HorizontalLinePreloader width={300} containerClass="h-5 mt-2" /> : (<div className="text-xs flex flex-row gap-4 mt-2">
                        <div><JobStatus  status={job.job_status_id.toString()} /></div>
                        <div><FiMapPin className="inline align-top mr-1" /> <span>{job.city}, {job.country}</span></div>
                        <div><BsCalendarWeek className="inline align-top mr-1" /> <span> {moment(job.created_at).format('lll')}</span></div>
                    </div>)
                }
                submenus={headerSubMenus}
                onSubMenuClick={onHeaderSubMenuClick}
            />
            {finalRender()}
        </>
    )
}

export default JobsDetail
