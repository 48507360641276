import React from "react";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {Dropdown} from "../components";
import {images} from "../assets/images";
import {AppStorage} from "../utils";

type TitleProps = {
    lang: "en" | "fr"
}
const Title = ({lang}: TitleProps) => {
    return(
        <div className="flex justify-center items-start mt-2">
            <img src={images[lang]} className="w-4 h-4 rounded-full" alt={"flag "+lang} />
            <span className="text-slate-500 text-xs pl-2 font-bold">{lang.toLocaleUpperCase()}</span>
        </div>
    )
}

const LanguageItem = ({lang}: TitleProps) => {
    const {t} = useTranslation()
    return(
        <div className="flex justify-center items-start">
            <img src={images[lang]} className="w-5 h-5 rounded-full" alt={"flag "+lang} />
            <span className="pl-3">{t("languages."+lang)}</span>
        </div>
    )
}

const ToggleLanguage = () => {
    const currentLanguage = AppStorage.getItem("i18nextLng") === "fr" ? "fr" : "en"
    const menus = [
        [
            {
                type: "custom",
                menuItem: {
                    title: <LanguageItem lang="fr" />,
                    onClick: () => i18next.changeLanguage("fr"),
                }
            },
            {
                type: "custom",
                menuItem: {
                    title: <LanguageItem lang="en" />,
                    onClick: () => i18next.changeLanguage("en"),
                }
            },
        ],
    ]
    return (
        <div className="">
            <Dropdown
                title="Options"
                position="right"
                menus={menus}
                type="custom"
                titleComponent={<Title lang={currentLanguage} />}
            />
        </div>
    )
}

export default ToggleLanguage