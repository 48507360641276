import React from "react";
import {BsPlusCircle} from "react-icons/bs";
import EmptyStateIcon from "../../assets/lotties/empty-state.json";
import {AppButton, LottieFile} from "../index";

type PropsType = {
    title: React.ReactNode
    description?: React.ReactNode
    buttonLabel?: React.ReactNode
    helpLink?: string
    onClick?: Function
}

const EmptyState: React.FC<PropsType> = (props: PropsType) => {
    const {title, description, onClick, buttonLabel = "Add new one", helpLink} = props
    return (
        <div className="flex justify-center items-center flex-col">
            <LottieFile animationData={EmptyStateIcon} width={100} height={100}/>
            <h3 className="font-bold pt-5 pb-1">{title}</h3>
            <p className="text-gray-500 w-1/2 text-center pb-5 text-xs">
                {description && description}
                {helpLink &&
                    <a href={helpLink} target="_blank" rel="noreferrer" className="text-primary-base underline">Comment
                        faire ?</a>}
            </p>
            {onClick && <AppButton
                icon={<BsPlusCircle/>}
                label={buttonLabel}
                onClick={onClick}
                size="sm"
                category="bloc"/>}
        </div>
    )
}

export default EmptyState
