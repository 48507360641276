import {Spinner} from "../index";
import {ButtonType} from "../../types/CommonTypes";

const AppButton = (props: ButtonType) => {
    const {
        label,
        icon,
        onClick,
        className,
        disabled,
        type = "button",
        isButton = true,
        size,
        bgColor,
        textColor,
        isLoading,
        isLoadingLabel,
    } = props
    let classes = " rounded shadow cursor-pointer  font-medium transition-all duration-500 ease-in-out "
    let bg = bgColor ? bgColor : " bg-primary-base hover:bg-primary-hover "
    classes += textColor ? textColor : " text-white "

    if (size === "xs") classes += " px-1 py-1 text-xs "
    else if (size === "sm") classes += "  px-4 py-2 text-sm "
    else if (size === "lg") classes += " px-6 py-5 text-2xl "
    else classes += " px-4 py-2 text-md "

    return (
        <>
            {
                isButton ?
                    <button
                        onClick={onClick ? () => onClick() : () => {
                        }}
                        disabled={disabled || isLoading}
                        className={`${className} ${bg} ${classes}`}
                        type={type}
                    >
                         <span className=" flex justify-center items-center">
                             {isLoading ? <Spinner label={isLoadingLabel}/> : <>
                                 {icon && <span className={`${label ? 'pr-3' : ''}`}>{icon}</span>} {label}
                             </>}
                        </span>
                    </button>
                    :
                    <div
                        onClick={onClick && (!disabled || !isLoading) ? () => onClick() : () => {
                        }}
                        className={`${className} ${bg} ${classes}`}
                    >
                        <span className=" flex justify-center items-center">
                            {isLoading ? <Spinner label={isLoadingLabel}/> : <>
                                {icon && <span className={`${label ? 'pr-3' : ''}`}>{icon}</span>} {label}
                            </>}
                        </span>
                    </div>
            }
        </>

    )
}

export default AppButton
