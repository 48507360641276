import React from "react";
import ContentLoader from "react-content-loader";

type Props = {
    width?: number
    height?: number
    className?: string
    containerClass?: string
}

const HorizontalLinePreloader = ({height=10, width=100, className="", containerClass=""}: Props) => {
 return(
     <ContentLoader
         speed={2}
         backgroundColor="#f3f3f3"
         foregroundColor="#ecebeb"
         className={containerClass}
     >
         <rect x="0" y="0" rx="3" ry="3" height={height} width={width}  className={className} />
     </ContentLoader>
 )
}

export  default HorizontalLinePreloader
