import {useQuery, useMutation} from "react-query";
import {GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type SignupBodyType = {
    firstname: string
    name: string
    email: string
    password: string
    confirm_password: string
    phone: string
    company_name: string,
    company_email: string,
    company_phone: string,
    company_country: string,
    company_city: string,
    company_address: string,
}

export const UseCheckUserEmail = (email?: string) =>
    useQuery(
        GetQueryKey("checkUserEmail"),
        async () => https.get(`${endpoints.checkUserExist}/${email}`),
        {staleTime: cacheTime.short, enabled: false});

export const UseSignup = () =>
    useMutation(async (body: SignupBodyType) => https.post(`${endpoints.signup}`, body));

export const UseResendValidationLink = () =>
    useQuery(
        GetQueryKey("resendValidationEmail"),
        async () => https.get(endpoints.sentEmailValidationLink),
        {staleTime: cacheTime.short, enabled: false});

export const UseGetCurrentUser = () =>
    useQuery(
        GetQueryKey("currentUser"),
        async () => https.get(endpoints.currentUser),
        {staleTime: cacheTime.long, enabled: false});

export const UseEditStaff = () =>
    useMutation(async (body: {[key: string]: any }) => https.patch(`${endpoints.editStaff}/${body.id}`, body));

export const UseSendStaffInvitation = () =>
    useMutation(async (body: {company_id: string | number, emails: string[] }) => https.patch(endpoints.sendRecruiterInvitation, body));

export const UseCheckStaffInvitationValidity = (email?: string) =>
    useQuery(
        GetQueryKey("checkInvitationValidity"),
        async () => https.get(`${endpoints.checkInvitationValidity}/${email}`),
        {staleTime: cacheTime.short, enabled: false});
