import http from "./httpService"
import {Company, LoginReq, User} from "../types/UserTypes";
import {ToastNotif} from "../helpers";
import {AppStorage} from "../utils";
import {localStorageKeys} from "../constants/common";
import endpoints from "../constants/endpoints";
import routes from "../constants/routes";

const login: Function = async (posts: LoginReq) => {
    const {data} = await http.post(endpoints.login, {...posts, lat: "-4.441931", lng: "15.266293"})
    if (data && !data.error) {
        ToastNotif.simple(data.description, "success")
        AppStorage.setItem(localStorageKeys.token, data.data.token)
        AppStorage.setItem(localStorageKeys.user, JSON.stringify(data.data.user))
        AppStorage.setItem(localStorageKeys.permissions, JSON.stringify(data.data.permissions))
        AppStorage.setItem(localStorageKeys.company, JSON.stringify(data.data.company))
    }
    return data
}

const checkCompanyExist: Function = async (email: string) => {
    const {data} = await http.get(endpoints.companyExists + email)
    if (data.error) return false
    return data.data[0]
}

const logout: Function = async () => {
    const {data} = await http.post(endpoints.logout, {token: getAuthToken(), lat: "-4.441931", lng: "15.266293"})
    if (data.error) {
        ToastNotif.simple(data.description, "error")
        return false
    }
    AppStorage.clearAll()
    window.location.href = routes.login
    return true
}

const getCurrentUser: () => User | null = () => {
    const user = AppStorage.getItem(localStorageKeys.user)
    return user ? JSON.parse(user) : null
}

const updateCurrentUser: (user: User) => void = (user: User) => AppStorage.setItem(localStorageKeys.user, JSON.stringify(user))

const updateCurrentCompany: (company: Company) => void = (company: Company) => AppStorage.setItem(localStorageKeys.company, JSON.stringify(company))

const getCurrentCompany: () => Company | null = () => {
    const company = AppStorage.getItem(localStorageKeys.company)
    return company ? JSON.parse(company) : null
}

const getAuthToken: () => string | null = () => AppStorage.getItem(localStorageKeys.token)

const getAccess: () => string | null = () => {
    const access = AppStorage.getItem(localStorageKeys.access)
    return access ? JSON.parse(access) : null
}

const exps = {
    login,
    logout,
    updateCurrentUser,
    getCurrentUser,
    getCurrentCompany,
    updateCurrentCompany,
    getAuthToken,
    getAccess,
}

export default exps
