import validator from "validator";
import endpoints from "../constants/endpoints";

const passwordStrength = (password: string) => {
    const errors = []
    if(!validator.isLength(password, {min:8, max: 1000})) errors.push("At least 8 characters")
    if(validator.isAlpha(password))  errors.push("At least 1 number")
    if(validator.isLowercase(password) || validator.isUppercase(password)) errors.push("At least 1 lowercase and 1 uppercase character")
    return errors
}

const loadCountries = async () => {
    return await fetch(`${endpoints.appUrl}data/countries.json`, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
        .then(res => res.json())
}

const loadCities = async (countryName: string) => {
    return await fetch(`${endpoints.appUrl}data/cities.json`, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
        .then(res => res.json())
        .then(data => data.filter((city: any) => city.country === countryName));
}

const trailingText = (str: string, chars: number = 50) => {
    if(str.length > chars) return `${str.slice(0, chars)}[...]`
    return str
}

const replaceAllChar = (str: string, old: string, n?: string) => {
    return str?.split(old).join(n || "")
}
const breakLine = (str: string) => {
    return replaceAllChar(str, "\n", "<br />")
}

const exports = {
    passwordStrength,
    loadCities,
    loadCountries,
    trailingText,
    breakLine,
    replaceAllChar
}

export default  exports
