import React from "react"
import ContentLoader from "react-content-loader"

const RecruitmentStepPreloader = () => (
    <ContentLoader
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        className="w-full h-[78px] mb-3"
    >
        <rect x="0" y="8" rx="3" ry="3" height="12" className="w-[20%]" />
        <rect x="0" y="30" rx="3" ry="3" height="6" className="w-[65%]" />
        <rect x="0" y="40" rx="3" ry="3" height="6" className="w-[65%]" />
        <rect x="90%" y="8" rx="3" ry="3" height="20" className="w-[30px]" />
    </ContentLoader>
)

export default RecruitmentStepPreloader
