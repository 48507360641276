import {useMutation, useQuery} from "react-query";
import {FormatUrlParams, GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type QueryParams = {
    status?: string
}


export const UseGetJobs = (options?: QueryParams) =>
    useQuery(GetQueryKey("jobs", options), async () => https.get(`${endpoints.jobs}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});

export const UseSaveJob = () =>
    useMutation(async (body: any) => https.post(endpoints.jobs, body));
