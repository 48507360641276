import {FC, forwardRef} from "react";
import Select from 'react-select'
import {SelectFormType} from "../../types/CommonTypes";
import InputHelperMessage from "./InputHelperMessage";

const AppSelect: FC<SelectFormType> = forwardRef((props: SelectFormType, ref: any) => {
    const {
        data,
        name,
        label,
        required,
        multiple,
        placeholder = "Selectionner...",
        selectedValue,
        selectedValueMulti = [],
        text = 'name',
        value = 'id',
        textPrefix = "",
        onChange,
        errorMessage,
        isSearchable = true,
        isClearable = true,
        isLoading = false, ...rest
    } = props

    return (
        <div className="mb-1 relative">
            <Select
                {...rest}
                ref={ref}
                name={name}
                options={data}
                isSearchable={isSearchable}
                placeholder={placeholder}
                isMulti={multiple}
                defaultValue={selectedValueMulti}
                defaultInputValue={selectedValue}
                controlShouldRenderValue
                getOptionLabel={(option) => {
                    if (typeof text === "string") return option[text]
                    else if (typeof text === "object") {
                        let res = ""
                        text.forEach((item: string, index: number) => {
                            res += option[item] ? `${textPrefix.length ? textPrefix[index] + " " : ""} ${option[item]}` + " " : ""
                        })
                        return res
                    }
                }}
                getOptionValue={(option) => option[value]}
                onChange={(option) => onChange && onChange(option)}
                isLoading={isLoading}
                styles={
                    {
                        placeholder: (provided, state) => ({
                            ...provided,
                            fontSize: 13
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            fontWeight: "normal",
                            fontSize: 13,
                            border: "none"
                        }),
                    }
                }
                className="border border-slate-400 rounded-md outline-none"
                isClearable={isClearable}
            />
            {label && <span className="absolute bg-white -top-2 left-3 text-xs font-bold px-3">{label}</span>}
            <InputHelperMessage message={errorMessage} type="error" />
        </div>
    )
})

export default AppSelect
