import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {images} from "../../assets/images";
import {Link} from "react-router-dom";
import routes from "../../constants/routes";
import {AppButton, AppInput, AppPhoneInput} from "../../components";
import {UseCheckUserEmail} from "../../services";
import {CommonFunctions} from "../../utils";

const RegisterStepOne = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const userData = state?.userData

    const {
        register,
        control,
        handleSubmit,
        setError,
        formState: {errors},
    } = useForm({
        mode: "onSubmit", defaultValues: {
            firstname: userData?.firstname,
            name: userData?.name,
            email: userData?.email,
            password: userData?.password,
            password_confirmation: userData?.password_confirmation,
            phone: userData?.phone,
        }
    });
    const [email, setEmail] = useState<string>()

    const {
        refetch: checkUserEmail,
        isRefetching: isCheckingEmailAgain,
        isLoading: isCheckingEmail
    } = UseCheckUserEmail(email)

    const onSubmit = (data: any) => {
        setEmail(data.email)
        const pwErrors = CommonFunctions.passwordStrength(data.password)
        if (pwErrors.length) {
            setError('password', {message: pwErrors[0]})
            return
        }
        if (data.password !== data.password_confirmation) {
            setError('password_confirmation', {message: "Les mots de passe de correspondent pas"})
            return
        }
        setTimeout(async () => {
            const {data: res} = await checkUserEmail()
            if (res?.data && res?.data[0] === true) {
                setError("email", {message: "Cette adresse email est déjà utilisée"})
                return
            }
            navigate(routes.signUp2, {state: {userData: data}})
        }, 400)
    }


    return (
        <div className="grid m-0  h-screen w-screen md:grid-cols-6 grid-cols-1 bg-white overflow-x-hidden">
            <div
                className="w-full h-full md:col-span-3 hidden md:block bg-primary-base">
                <p className="pt-8 pl-8"><img src={images.logoWhite} className="w-1/3" alt="Logo Darketix"/></p>

            </div>
            <div className="col-span-3 flex flex-col justify-center px-16">
                <div className="relative">
                    <div className="mb-5">
                        <h2 className="text-gray-800 text-3xl font-bold">Inscription</h2>
                        <p className="text-gray-500 text-xs">Recrutez les meilleurs pour votre entreprise.</p>
                    </div>
                    <form className="grid gap-x-4 md:grid-cols-2 grid-cols-1" onSubmit={handleSubmit(onSubmit)}>
                        <AppInput
                            {...register("firstname", {required: "Entrez votre prénom"})}
                            label="Prénom"
                            type="text"
                            name="firstname"
                            error={!!(errors && errors["firstname"])}
                            errorMessage={errors["firstname"]?.message?.toString()}
                        />
                        <AppInput
                            {...register("name", {required: "Entrez votre nom"})}
                            label="Nom"
                            type="text"
                            name="name"
                            error={!!(errors && errors["name"])}
                            errorMessage={errors["name"]?.message?.toString()}
                        />
                        <div className="col-span-2 mt-2">
                            <AppInput
                                {...register("email", {required: "Entrez votre adresse email"})}
                                label="Email"
                                type="email"
                                name="email"
                                error={!!(errors && errors["email"])}
                                errorMessage={errors["email"]?.message?.toString()}
                            />
                        </div>
                        <div className="col-span-2 mt-2 mb-2">
                            <Controller
                                control={control}
                                name="phone"
                                rules={{
                                    required: {value: true, message: "Entrez votre numéro téléphone"},
                                }}
                                render={({field}) => (
                                    <AppPhoneInput
                                        {...field}
                                        name="phone"
                                        label="Téléphone"
                                        required
                                        error={!!(errors["phone"])}
                                        errorMessage={errors["phone"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>
                        <AppInput
                            {...register("password", {required: "Entrez un mot de passe"})}
                            label="Mot de passe"
                            type="password"
                            name="password"
                            error={!!(errors && errors["password"])}
                            errorMessage={errors["password"]?.message?.toString()}
                        />
                        <AppInput
                            {...register("password_confirmation", {required: "Entrez de nouveau le mot de passe"})}
                            label="Confirmer mot de passe"
                            type="password"
                            name="password_confirmation"
                            error={!!(errors && errors["password_confirmation"])}
                            errorMessage={errors["password_confirmation"]?.message?.toString()}
                        />

                        <div className="col-span-2 mt-5">
                            <AppButton
                                isLoading={isCheckingEmail || isCheckingEmailAgain}
                                size="md"
                                type="submit"
                                className="w-[100%] rounded-[50px] py-4"
                                bgColor="bg-primary-base hover:bg-primary-hover"
                                textColor="text-white"
                                label="Valider"
                            />

                        </div>

                    </form>

                    <p className="text-center mt-10 text-sm text-gray-500">
                        Votre entreprise utilise déja Darketix ? <Link to={routes.login}
                                                                       className="text-primary-base ml-2 font-bold hover:text-gray-700"> Connectez-vous
                        !</Link>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default RegisterStepOne
