import React from "react";
import {HorizontalMenus} from "../index";
import {HorizontalMenusPropsType} from "../lists/HorizontalMenus";

type PropsType = {
    title: React.ReactNode,
    subTitle?: React.ReactNode,
    children?: React.ReactNode,
    submenus?: HorizontalMenusPropsType[]
    onSubMenuClick?: Function
}
const TitleBar: React.FC<PropsType> = ({children, title, subTitle, submenus, onSubMenuClick}: PropsType) => {
    return (
        <div className={`${submenus ? "pt-3" : "py-3"}  px-5 bg-white border-b-1 border-b border-b-slate-200`}>
            <div className="flex justify-between items-center">
                <div>
                    <div className="font-bold text-ms">{title}</div>
                    <div className="text-gray-600 text-xs">{subTitle}</div>
                </div>
                <div> {children} </div>
            </div>
            {submenus && <HorizontalMenus onItemClick={onSubMenuClick ? onSubMenuClick : ()=>{}} menus={submenus}/>}
        </div>
    )
}

export default TitleBar
