import {BlockingOverlay, LottieFile} from "../index";
import loader from "../../assets/lotties/loader.json"

const OverLoader: React.FC = () => {
    return (
        <BlockingOverlay
            children={
                <div className="absolute right-[44%] top-[43%]">
                    <LottieFile animationData={loader} width={170} height={170} />
                </div>
            }
        />

    )
}

export default OverLoader