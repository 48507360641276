import React from "react";

type PropsType = {
    icon?: React.ReactNode
    title: string | React.ReactNode
    badgeValue?: string
}

const ListItemBadge = (props: PropsType) => {
    const {badgeValue, title, icon} = props
    return (
        <div className="flex flex-row justify-between items-center border-b text-sm pb-2 mt-3">
            <div className="flex flex-row items-center">
                {icon && icon}
                <span className="text-slate-700 font-semibold ml-2 text-[13px]">{title}</span>
            </div>
            {badgeValue && <span className="border py-0.5 text-xs px-1.5 rounded border-gray-400">{badgeValue}</span>}
        </div>
    )
}

export default ListItemBadge
