import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {ThemeContextProvider, UserContextProvider} from "./contexts";
import {LoggedRoute, ProtectedRoute} from "./helpers";
import {
    Home,
    Login,
    NotFound,
    JobsList,
    EmailChecking,
    Welcome,
    EmailValidated,
    EmailAlreadyValidated,
    TokenExpired,
    JobsCreate,
    JobsDetail,
    RegisterStepOne,
    RegisterStepTwo,
    JobsCreateStepOne,
    JobsCreateStepTwo,
    JobsCreateStepThree,
    JobsCreateStepFour
} from "./screens";
import {HeaderBar, SideBar} from "./components";
import "./App.css"
import routes from "./constants/routes";


function App() {
    const location = useLocation();
    const unauthRoutes = [routes.login, routes.signUp, routes.signUp2, routes.mailValidated, routes.tokenExpired, routes.mailAlreadyValidated, routes.invitationSignup]
    return (
        <div className="bg-base-white dark:bg-base-black transition duration-200 ease-in-out">
            <ThemeContextProvider>
                <UserContextProvider>
                    {location && unauthRoutes.includes(location.pathname) ? (
                        <Routes>
                            <Route element={<LoggedRoute/>}>
                                <Route path={routes.login} element={<Login/>}/>
                                <Route path={routes.signUp} element={<RegisterStepOne/>}/>
                                <Route path={routes.signUp2} element={<RegisterStepTwo/>}/>
                                <Route path={routes.mailValidated} element={<EmailValidated/>}/>
                                <Route path={routes.mailAlreadyValidated} element={<EmailAlreadyValidated/>}/>
                                <Route path={routes.tokenExpired} element={<TokenExpired/>}/>
                                <Route path={routes.invitationSignup} element={<TokenExpired/>}/>
                            </Route>
                        </Routes>
                    ) : (
                        <div className="flex">
                            <EmailChecking/>
                            <Welcome/>
                            <SideBar/>
                            <div className="w-full overflow-y-hidden pb-10" style={{backgroundColor: "#f2f3f6"}}>
                                <HeaderBar/>
                                <Routes>
                                    <Route element={<ProtectedRoute/>}>
                                        <Route path="/" element={<Home/>}/>

                                        <Route path={routes.jobs} element={<JobsList/>}/>
                                        <Route path={routes.jobNew} element={<JobsCreate/>}/>
                                        <Route path={routes.jobCreateOfferDetails} element={<JobsCreateStepOne />}/>
                                        <Route path={routes.jobCreateProfileSearched} element={<JobsCreateStepTwo />}/>
                                        <Route path={routes.jobCreateRecruitmentProcess} element={<JobsCreateStepThree />}/>
                                        <Route path={routes.jobCreateUploadDocuments} element={<JobsCreateStepFour />}/>
                                        <Route path={`${routes.jobs}/:id`} element={<JobsDetail/>}/>
                                        <Route path={`${routes.jobEdit}/:id`} element={<JobsCreate action="edit"/>}/>

                                        <Route path="*" element={<NotFound/>}/>
                                    </Route>
                                </Routes>
                            </div>
                        </div>
                    )}
                </UserContextProvider>
            </ThemeContextProvider>

            <Toaster position="top-right" reverseOrder={false}/>
        </div>
    );
}

export default App;
