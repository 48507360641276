import {forwardRef, LegacyRef} from "react";
import {RadioFormType} from "../../types/CommonTypes";

const AppRadioBox: React.FC<RadioFormType> = forwardRef((props: RadioFormType, ref: LegacyRef<any>) => {
    const {
        onClick,
        name,
        id,
        label,
        value,
        selected = false,
        ...rest
    } = props
    return (
        <label
            className="cursor-pointer"
            htmlFor={id}
            onClick={onClick ? () => onClick(value) : () => {}}
        >
            <input id={id} checked={selected} name={name} {...rest} value={value} type="radio" ref={ref}/>
            {label && <span className="ml-3 text-gray-700 font-medium text-sm">{label}</span>}
        </label>
    )
})

export default AppRadioBox
