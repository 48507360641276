import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import moment from "moment/moment";
import {
    AppButton, AppDatePicker,
    AppInput, AppSelect, FileDropZone,
    InputHelperMessage, RecruitmentStep, RecruitmentStepPreloader, Spinner,
    Stepper,
    StepperControl,
    SwitchInput,
    TitleBar
} from "../../components";
import {StepperContext} from "../../contexts/StepperContext";
import {useForm, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {BsFileEarmarkText, BsInputCursorText, BsTrash} from "react-icons/bs"
import countiesData from "../../constants/countries.json";
import {CommonFunctions} from "../../utils";
import {useMutation, useQuery} from "react-query";
import {https} from "../../services";
import {ToastNotif} from "../../helpers";
import i18next from "i18next";
import {RecruitmentAction, RecruitmentStepItem} from "../../types/CommonTypes";
import {applyDocumentFormats, dateOptions} from "../../constants/common";
import {useNavigate, useParams} from "react-router-dom";
import routes from "../../constants/routes";
import endpoints from "../../constants/endpoints";


type StepType = {
    steps?: any
    currentStep: number
    onClick: Function
    onConfirm?: Function
    defaultData?: any
}

type DynamicFields = {
    type: "file" | "question",
    title : string,
    format: string
}


const StepOne = ({steps, currentStep, onClick, defaultData}: StepType) => {
    const {t} = useTranslation()
    const {stepData, setStepData} = useContext(StepperContext)
    const [selectedCountry, setSelectedCountry] = useState('Democratic Republic of the Congo')
    const [cities, setCities] = useState([])
    const [subDomains, setSubDomains] = useState([])
    const [publishedDate, setPublishedDate] = useState<Date|string>(moment(stepData.publication_date).format('YYYY-MM-DD'));
    const [expirationDate, setExpirationDate] = useState<Date|string>(moment(stepData.expiration_date).format('YYYY-MM-DD'));
    const [isSubDomainsLoading, setIsSubDomainsLoading] = useState(false)
    const {register, control, formState: {errors}, watch} = useForm({
        mode: "all", defaultValues: {
            title: stepData.title || defaultData?.title,
            description: stepData.description || defaultData?.description,
            remote: stepData.remote || defaultData?.remote || false,
            country: stepData.country || defaultData?.country,
            city: stepData.city,
            min_salary: stepData.min_salary || defaultData?.min_salary,
            max_salary: stepData.max_salary || defaultData?.max_salary,
            salary_method_id: stepData.salary_method_id,
            domain_id: stepData.domain_id,
            subDomains: stepData.subDomains,
            contract_type_id: stepData.contract_type_id,
        }
    });

    const {isLoading, data} = useQuery(["salaryMethods"], async () => {
        const res = await https.get(endpoints.salaryMethods)
        return res.data
    }, {staleTime: 120000})
    const salaryMethods: [] = data || []

    const {isLoading: isDomainLoading, data: domainData} = useQuery(["domains"], async () => {
        const res = await https.get(endpoints.domains)
        return res.data
    }, {staleTime: 120000})
    const jobDomains: [] = domainData || []

    const {isLoading: isContractLoading, data: ctData} = useQuery(["contractTypes"], async () => {
        const res = await https.get(endpoints.contractTypes)
        return res.data
    }, {staleTime: 120000})
    const contractTypes: [] = ctData || []

    const getCities = async (countryName: string) => {
        setCities(await CommonFunctions.loadCities(countryName))
    }

    useEffect(() => {
        getCities(selectedCountry)
        if(defaultData?.domain?.id) onSelectDomain(defaultData?.domain?.id)
    }, [])

    const onSelectCountry = (country: { country_name: string, [key: string]: string }) => {
        getCities(country.country_name)
        setSelectedCountry(country.country_name)
    }

    const onSelectDomain = async (domainID: string) => {
        setIsSubDomainsLoading(true)
        const res = await https.get(endpoints.subDomainsByDomain + domainID)
        if (!res.error) setSubDomains(res.data)
        setIsSubDomainsLoading(false)
    }

    useEffect(() => {
        const subscription = watch(async (value, {name, type}) => {
            if (name === "country") onSelectCountry(value.country)
            if (name === "domain_id") await onSelectDomain(value.domain_id.id)
            setStepData({...stepData, ...value})
        });
        return () => subscription.unsubscribe();
    }, [watch])

    const onNext = (newStep: number) => {
        const publication_date = new Date(publishedDate.toString()).toLocaleDateString("en-EN", dateOptions)
        const expiration_date = new Date(expirationDate.toString()).toLocaleDateString("en-EN", dateOptions)
        setStepData({
            ...stepData,
            published_date: moment(publication_date, "MM-DD-YYYY").format("YYYY-MM-DD").toString(),
            expiration_date: moment(expiration_date, "MM-DD-YYYY").format("YYYY-MM-DD").toString(),
        })
        if (Object.keys(stepData).length > 0) {
            const empties = Object.keys(stepData).filter((item) => stepData[item] === undefined || stepData[item] === "")
            if (empties.includes("title") ||
                empties.includes("description") ||
                empties.includes("country") ||
                empties.includes("domain_id") ||
                empties.includes("contract_type_id") ||
                empties.includes("city")) ToastNotif.simple(i18next.t("validator.fill_all_required_fields"), "error")
            else onClick(newStep)
        } else ToastNotif.simple(i18next.t("validator.fill_all_required_fields"), "error")
    }
    return (
        <div>
            <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                <div className="mb-[15px] col-span-2 ">
                    <AppInput
                        {...register("title", {required: t("validator.field_required")})}
                        label={t("offer.titleOfOffer")}
                        name="title"
                        required
                    />
                    {errors && errors["title"] &&
                        <InputHelperMessage type="error" message={errors["title"].message}/>}
                </div>
                <div className="mb-[15px] col-span-2 grid grid-cols-4 gap-x-5">
                    <div className="col-span-2">
                        <Controller
                            control={control}
                            name="contract_type_id"
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    required
                                   label={t("offer.typeOfContract")}
                                   name="contract_type_id"
                                   selectedValue={stepData.contract_type_id?.name || defaultData?.contract_type?.name}
                                   placeholder=""
                                   text="name"
                                   value="id"
                                    //multiple
                                   data={contractTypes}
                                   isLoading={isContractLoading}
                                />
                            )}
                        />
                        {errors && errors["contract_type_id"] &&
                            <InputHelperMessage type="error" message={errors["contract_type_id"].message}/>}
                    </div>
                    <div className="col-span-2">
                        <Controller
                            control={control}
                            name="domain_id"
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    required
                                   label={t("offer.jobDomain")}
                                   name="domain_id"
                                   selectedValue={stepData.domain_id?.name || defaultData?.domain?.name}
                                   placeholder=""
                                   text="name"
                                   value="id"
                                    //multiple
                                   data={jobDomains}
                                   isLoading={isDomainLoading}
                                />
                            )}
                        />
                        {errors && errors["domain_id"] &&
                            <InputHelperMessage type="error" message={errors["domain_id"].message}/>}
                    </div>
                </div>
                <div className="mb-[15px] col-span-2">
                    <Controller
                        control={control}
                        name="subDomains"
                        render={({field}) => (
                            <AppSelect
                                {...field}
                               label="Professional sub domains"
                               name="subDomains"
                               selectedValueMulti={stepData.subDomains || defaultData?.sub_domains}
                               placeholder=""
                               text="name"
                               multiple
                               value="id"
                               data={subDomains}
                               isLoading={isSubDomainsLoading}
                            />
                        )}
                    />
                </div>
                <div className="mb-[15px] col-span-2">
                    <AppInput
                        {...register("description", {required: t("validator.field_required")})}
                        label={t("common.description")}
                        name="description"
                        required
                        isTextarea
                        rows={3}
                    />
                    {errors && errors["description"] &&
                        <InputHelperMessage type="error" message={errors["description"].message}/>}
                </div>
                <div className="mb-[15px] col-span-2 grid grid-cols-4 gap-x-5">
                    <div className="col-span-2">
                        <AppDatePicker
                            label="Date de publication"
                            name="publication_date"
                            onChange={(value:Date) => {
                                setPublishedDate(value)
                            }}
                            value={new Date(publishedDate)}
                            required
                        />
                    </div>
                    <div className="col-span-2">
                        <AppDatePicker
                            label="Date de cloture"
                            name="expiration_date"
                            onChange={(value:Date) => {
                                setExpirationDate(value)
                            }}
                            value={new Date(expirationDate)}
                            //defaultValue={expirationDate.toString()}
                            required
                        />
                    </div>
                </div>
                <div className="mb-[15px] col-span-2">
                    <h4 className="mb-2 font-bold text-sm">{t("common.salary")}</h4>
                    <div className="grid grid-cols-6 gap-x-5">
                        <div className="col-span-2">
                            <AppInput
                                {...register("min_salary")}
                                placeholder="Min"
                                name="min_salary"
                            />
                            {errors && errors["min_salary"] &&
                                <InputHelperMessage type="error" message={errors["min_salary"].message}/>}
                        </div>
                        <div className="col-span-2">
                            <AppInput
                                {...register("max_salary")}
                                placeholder="Max"
                                name="max_salary"
                            />
                            {errors && errors["max_salary"] &&
                                <InputHelperMessage type="error" message={errors["max_salary"].message}/>}
                        </div>
                        <div className="col-span-2">
                            <Controller
                                control={control}
                                name="salary_method_id"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                       name="salary_method_id"
                                       selectedValue={stepData?.salary_method_id?.name || defaultData?.salary_method?.name}
                                       placeholder={t("common.typeOfRemuneration")}
                                       text="name"
                                       value="id"
                                       data={salaryMethods}
                                       isLoading={isLoading}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                <h4 className="mb-3 font-bold text-lg">{t("common.location")}</h4>
                <div className="mb-[15px] col-span-2 grid grid-cols-5 gap-x-5">
                    <div className="col-span-2">
                        <Controller
                            control={control}
                            name="country"
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    required
                                   name="country"
                                   label={t("common.country")}
                                   value="country_name"
                                   text="country_name"
                                   data={countiesData}
                                   selectedValue={stepData.country?.country_name || defaultData?.country}
                                />
                            )}
                        />
                        {errors && errors["country"] &&
                            <InputHelperMessage type="error" message={errors["country"].message}/>}
                    </div>
                    <div className="col-span-2">
                        <Controller
                            control={control}
                            name="city"
                            render={({field}) => (
                                <AppSelect
                                    {...field}
                                    name="city"
                                   label={t("common.city")}
                                   text="name"
                                   value="name"
                                   required
                                   placeholder=""
                                   data={cities}
                                   selectedValue={stepData.city?.name || defaultData?.city}
                                />
                            )}
                        />
                        {errors && errors["city"] &&
                            <InputHelperMessage type="error" message={errors["city"].message}/>}
                    </div>
                    <div className="col-span-1 mt-5">
                        <Controller
                            control={control}
                            name="remote"
                            render={({field: {onChange, onBlur, value, ref}}) => (
                                <SwitchInput label="Remotely ?" value={value} onChange={onChange}/>)
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-3 place-items-center">
                <div/>
                <div/>
                <StepperControl
                    buttonSize="md"
                    onConfirm={() => {
                    }}
                    onClick={onNext}
                    steps={steps}
                    currentStep={currentStep}
                />

            </div>

        </div>
    )
}

const StepTwo = ({steps, currentStep, onClick}: StepType) => {
    const {t} = useTranslation()
    const {stepData, setStepData} = useContext(StepperContext)
    const [secondForm, setSecondForm] = useState<{ [key: string]: string }>({})
    const {register, control, formState: {errors}, watch} = useForm({
        mode: "all", defaultValues: {
            years_experience_required: stepData.years_experience_required,
            experience_level_id: stepData.experience_level_id,
            study_level_id: stepData.study_level_id,
            primaryLanguages: stepData.primaryLanguages,
            secondaryLanguages: stepData.secondaryLanguages,
            domainFormations: stepData.domainFormations,
            softSkills: stepData.softSkills,
            technicalSkills: stepData.technicalSkills,
        }
    });

    const [dynamicFields, setDynamicFields] = useState<DynamicFields[]>(stepData.dynamicFields ? stepData.dynamicFields : [])

    const {isLoading: isExpLevelLoading, data: elData} = useQuery(["experienceLevels"], async () => {
        const res = await https.get(endpoints.experienceLevels)
        return res.data
    }, {staleTime: 120000})
    const experienceLevels: [] = elData || []

    const {isLoading: isStudLevelLoading, data: slData} = useQuery(["studyLevels"], async () => {
        const res = await https.get(endpoints.studyLevels)
        return res.data
    }, {staleTime: 120000})
    const studyLevels: [] = slData || []

    const {isLoading: isDomForLoading, data: dfData} = useQuery(["domainFormations"], async () => {
        const res = await https.get(endpoints.domainFormations)
        return res.data
    }, {staleTime: 120000})
    const domainFormations: [] = dfData || []

    const {isLoading: isLangLoading, data: lData} = useQuery(["languages"], async () => {
        const res = await https.get(endpoints.languages)
        return res.data
    }, {staleTime: 120000})
    const languages: [] = lData || []

    const {isLoading: isSoftLoading, data: ssData} = useQuery(["softSkills"], async () => {
        const res = await https.get(endpoints.softSkills)
        return res.data
    }, {staleTime: 120000})
    const softSkills: [] = ssData || []

    const {isLoading: isTechLoading, data: tsData} = useQuery(["techSkills"], async () => {
        const res = await https.get(endpoints.techSkills)
        return res.data
    }, {staleTime: 120000})
    const techSkills: [] = tsData || []

    const addDynamicField = (type: "file" | "question") => {
        setDynamicFields([...dynamicFields, {title : "", format: "", type}])
    }

    const deleteDynamicField = async (index: number) => {
        let temp = [...dynamicFields];
        temp.splice(index, 1)
        setDynamicFields(temp)
    }

    const handleDynamicFormChange = (index: number, item:DynamicFields) => (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        let data = [...dynamicFields];
        data[index] = {...item, title: target.value}
        setDynamicFields(data);
    }

    const handleDynamicSelect = (option: {value: string, label: string}, index: number, item:DynamicFields) => {
        let data = [...dynamicFields];
        data[index] = {...item, format: option.label}
        setDynamicFields(data);
    }

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            setStepData({...stepData, ...value})
            setSecondForm({...secondForm, ...value})
        });
        return () => subscription.unsubscribe();
    }, [watch])

    const onNext = (newStep: number, direction: string) => {
        const applyQuestions = dynamicFields.filter(item =>  item.type === "question" ).map(item => item.title)
        const applyDocsTitles = dynamicFields.filter(item =>  item.type === "file" ).map(item => item.title)
        const applyDocsFormats = dynamicFields.filter(item =>  item.type === "file" ).map(item => item.format)
        setStepData({
            ...stepData,
            dynamicFields: dynamicFields,
            applyFormQuestions: applyQuestions,
            applyFormDocumentsTitles: applyDocsTitles,
            applyFormDocumentsTypes: applyDocsFormats
        })
        if(direction === "back") {
            onClick(newStep)
            return
        }
        const empties = Object.keys(secondForm).filter((item) => secondForm[item] === undefined || secondForm[item] === "")
        if (empties.length) ToastNotif.simple(i18next.t("validator.fill_all_required_fields"), "error")
        else onClick(newStep)
    }

    return (
        <div>

            <>
                <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                    <div className="mb-[15px] col-span-2">
                        <h4 className="mb-2 font-bold text-lg">Expérience souhaitée</h4>
                        <div className="grid grid-cols-6 gap-x-5">
                            <div className="col-span-2">
                                <AppInput
                                    {...register("years_experience_required")}
                                    label="Année d'expérience"
                                    name="years_experience_required"
                                    required
                                />
                                {errors && errors["years_experience_required"] &&
                                    <InputHelperMessage type="error"
                                                        message={errors["years_experience_required"].message}/>}
                            </div>
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="experience_level_id"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Niveau professionnel"
                                                   name="experience_level_id"
                                                   selectedValue={stepData.experience_level_id?.name}
                                                   placeholder=""
                                                   text="name"
                                                   value="id"
                                                   data={experienceLevels}
                                                   isLoading={isExpLevelLoading}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="study_level_id"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Niveau d'études"
                                                   name="study_level_id"
                                                   selectedValue={stepData.study_level_id?.name}
                                                   placeholder=""
                                                   text="name"
                                                   value="id"
                                                   data={studyLevels}
                                                   isLoading={isStudLevelLoading}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-[15px] col-span-2">
                        <Controller
                            control={control}
                            name="domainFormations"
                            render={({field}) => (
                                <AppSelect {...field}
                                           required
                                           multiple
                                           label="Domaine d'etudes et formations"
                                           name="domainFormations"
                                           selectedValueMulti={stepData.domainFormations}
                                           placeholder=""
                                           text="name"
                                           value="id"
                                           data={domainFormations}
                                           isLoading={isDomForLoading}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                    <h4 className="mb-2 font-bold text-lg">Compétences</h4>
                    <div className="mb-[15px] col-span-2">
                        <div className="grid grid-cols-4 gap-x-5">
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="primaryLanguages"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Langues principales"
                                                   name="primaryLanguages"
                                                   selectedValueMulti={stepData.primaryLanguages}
                                                   placeholder=""
                                                   text="name"
                                                   multiple
                                                   value="id"
                                                   data={languages}
                                                   isLoading={isLangLoading}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="secondaryLanguages"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Langues sécondaires"
                                                   name="secondaryLanguages"
                                                   selectedValueMulti={stepData.secondaryLanguages}
                                                   placeholder=""
                                                   multiple
                                                   text="name"
                                                   value="id"
                                                   data={languages}
                                                   isLoading={isLangLoading}
                                        />
                                    )}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="mb-[15px] col-span-2">
                        <div className="grid grid-cols-4 gap-x-5">
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="softSkills"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Soft skills"
                                                   name="softSkills"
                                                   selectedValueMulti={stepData.softSkills}
                                                   placeholder=""
                                                   text="name"
                                                   multiple
                                                   value="id"
                                                   data={softSkills}
                                                   isLoading={isSoftLoading}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="technicalSkills"
                                    render={({field}) => (
                                        <AppSelect {...field} required
                                                   label="Technical skills"
                                                   name="technicalSkills"
                                                   selectedValueMulti={stepData.technicalSkills}
                                                   placeholder=""
                                                   multiple
                                                   text="name"
                                                   value="id"
                                                   data={techSkills}
                                                   isLoading={isTechLoading}
                                        />
                                    )}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                    <h4 className="mb-0 font-bold text-lg">Questions de présélection</h4>
                    <p className="text-xs text-gray-500">Les candidats devront répondre à ces questions ou joindre des documents spécifiques avant de pouvoir postuler</p>
                    <div className="mt-2">
                        <AppButton
                            icon={<BsInputCursorText />}
                            label="Ajouter une question"
                            onClick={() => addDynamicField("question")}
                            category="bloc"
                            size="xs"
                            //color="primary-outline"
                        />

                        <AppButton
                            icon={<BsFileEarmarkText />}
                            className="ml-2"
                            label="Ajouter un fichier a joindre"
                            onClick={() => addDynamicField("file")}
                            category="bloc"
                            size="xs"
                            //color="secondary-outline"
                        />
                    </div>
                    <div className="mt-5 mb-5">
                        {dynamicFields && dynamicFields.length ? dynamicFields.map((item, index) => (
                            <div key={index} className="grid grid-cols-12 items-center mt-4 gap-4">

                                {item.type === "question"
                                    ? (<div className="col-span-10">
                                        <AppInput
                                            placeholder="Saisissez la question"
                                            className="w-full"
                                            name={"hello"}
                                            value={dynamicFields[index].title}
                                            //onChange={handleDynamicFormChange(index, item)}
                                        />
                                    </div>)

                                    : (<div className="col-span-10 ">
                                        <div className="grid grid-cols-6 gap-4">
                                           <div className="col-span-4">
                                               <AppInput
                                                   placeholder="Déscription du fichier à joindre"
                                                   className="w-full"
                                                   name="fileTitle"
                                                   value={dynamicFields[index].title}
                                                   //onChange={handleDynamicFormChange(index, item)}
                                               />
                                           </div>
                                            <div className="col-span-2">
                                                <AppSelect
                                                    placeholder="Format"
                                                    name="format"
                                                    data={applyDocumentFormats}
                                                    onChange={(option: {value: string, label: string}) => handleDynamicSelect(option, index, item)}
                                                    selectedValue={dynamicFields[index].format}
                                                />
                                            </div>

                                        </div>
                                    </div>)}
                                <div
                                    className="col-span-1 cursor-pointer text-red-600 text-xl justify-self-end"
                                    title="Supprimer ce champs"
                                    onClick={() => deleteDynamicField(index)}>
                                    <BsTrash />
                                </div>
                            </div>
                            )
                        ): null}
                    </div>


                </div>

                <div className="flex flex-row">
                    <div className="w-1/2" />
                    <StepperControl
                        buttonSize="md"
                        onConfirm={() => {
                        }}
                        onClick={onNext}
                        steps={steps}
                        currentStep={currentStep}
                    />
                </div>
            </>

        </div>
    )
}

const StepThree = ({steps, currentStep, onClick}: StepType) => {
    const {stepData, setStepData} = useContext(StepperContext)
    const [formData, setFormData] = useState(stepData.steps)

    const {isLoading: isStepsLoading, data: recuitStepData} = useQuery(["recruitmentSteps"], async () => {
        const res = await https.get(`${endpoints.recruitmentSteps}?fields=id,title,description,flag_color&sortDirection=ASC`)
        return res.data
    }, {staleTime: 120000})
    const recruitmentSteps: [] = recuitStepData || []

    const {data: stepActionsData} = useQuery(["recruitmentStepActions"], async () => {
        const res = await https.get(`${endpoints.recruitmentStepActions}?fields=id,title,slug,created_at,description`)
        return res.data
    }, {staleTime: 120000})
    const recruitmentStepActions: {[key:string]: any}[] = stepActionsData || []
    let actions: RecruitmentAction[] = []
    if(recruitmentStepActions.length){
         actions = recruitmentStepActions.map(item => {
            return {id: item.id, title: item.title, description: item.description, content: item.description, isSelected: false, data: null}
        })
    }

    const getReturnedActions = (id: number, data: any) => {
        setFormData({
            ...formData,
            [id]: data,
        });
    }

    const onNext = (newStep: number) => {
        if(formData && Object.keys(formData).length){
            const recruitmentSteps = Object.keys(formData)
            let recruitmentStepActions: any[][]  = []
            let recruitmentStepActionValues: any[][] = []
            for (let i = 0; i < recruitmentSteps.length; i++) {
                const stepId = recruitmentSteps[i]
                recruitmentStepActions[i] = []
                recruitmentStepActionValues[i] = []
                console.log(stepId, recruitmentStepActions[i])
                for (let j = 0; j < formData[stepId].length; j++) {
                    const item: RecruitmentAction = formData[stepId][j]
                    recruitmentStepActions[i][j] = item.isSelected ? item.id.toString() : null
                    recruitmentStepActionValues[i][j] = item.isSelected && item.data ? item.data : null
                }
            }
            setStepData({
                ...stepData,
                steps: formData,
                recruitmentSteps,
                recruitmentStepActions,
                recruitmentStepActionValues,
            })
        }
        onClick(newStep)
    }
    return (
        <div>
            <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                <h4 className="font-bold text-lg">Processus de recrutement</h4>
                <small className="text-gray-500">Comment votre processus de recrutement va se dérouler.</small>

                <div className="mt-4">
                    {recruitmentSteps.length && recruitmentStepActions.length ? recruitmentSteps.map((step: RecruitmentStepItem, index) =>
                            <RecruitmentStep
                                defaultValues={stepData.steps && stepData.steps[step?.id] ? stepData.steps[step?.id] : {}}
                                actions={actions}
                                onReturn={getReturnedActions}
                                item={step}
                                key={index}
                            />) :
                        <div>
                            {[1,2,3,4].map(item => <RecruitmentStepPreloader key={item}/>)}
                        </div>
                    }
                </div>


            </div>
            <div className="flex flex-row">
                <div className="w-1/2" />
                <StepperControl
                    buttonSize="md"
                    onConfirm={() => {
                    }}
                    onClick={onNext}
                    steps={steps}
                    currentStep={currentStep}
                />
            </div>
        </div>
    )
}

const StepFour = ({steps, currentStep, onClick}: StepType) => {
    const navigate = useNavigate()
    const {stepData, setStepData} = useContext(StepperContext)
    const onNext = (newStep: number) => onClick(newStep)

    const {isLoading, mutate: saveJob} = useMutation(async (body) => {
        const result =  await https.post(endpoints.jobs, body)
        if(!result.error){
            ToastNotif.simple(result.description, "success")
            setStepData({})
            navigate(routes.jobs)
            return
        }
        ToastNotif.simple(result.description, "error")
    })

    const onConfirm = async () => {
        const body = {
            ...stepData,
            country: stepData.country.country_name,
            city: stepData.city.name,
            salary_method_id: stepData.salary_method_id.id,
            domain_id: stepData.domain_id.id,
            job_status_id: 1,
            contract_type_id: stepData.contract_type_id.id,
            experience_level_id: stepData.experience_level_id.id,
            study_level_id: stepData.study_level_id.id,
            subDomains:  stepData.subDomains.map((item: any) => item.id),
            domainFormations:  stepData.domainFormations.map((item: any) => item.id),
            primaryLanguages:  stepData.primaryLanguages.map((item: any) => item.id),
            secondaryLanguages:  stepData.secondaryLanguages.map((item: any) => item.id),
            softSkills:  stepData.softSkills.map((item: any) => item.id),
            technicalSkills:  stepData.technicalSkills.map((item: any) => item.id),
        }
        await saveJob(body)
    }
    return (
        <div>
            <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                <h4 className="mb-0 font-bold text-lg">Documents de l'offre</h4>
                <p className="text-xs text-gray-500">Attachez les documents relatifs a l'offre. Ex: le descriptif, le job description, une photo...</p>

                <div className="my-4">
                    <FileDropZone />
                </div>
            </div>

            <div className="flex flex-row">
                <div className="w-1/2" />
                <StepperControl
                    buttonSize="md"
                    onConfirm={onConfirm}
                    onClick={onNext}
                    steps={steps}
                    disabled={isLoading}
                    currentStep={currentStep}
                    nextButtonLabel={isLoading ? <Spinner /> : "Save"}
                />
            </div>
        </div>
    )
}


const JobsCreate = ({action="create"}: {action?: string}) => {
    const steps = [
        {title: "Offers details", description: "Title, requirements, and all details"},
        {title: "Profile", description: "All the requirements related to the type of profile sought"},
        {title: "Process", description: "How the recruitment will happen"},
        {title: "Documents", description: "Attach files if necessary"}
    ]
    const params = useParams();
    const [currentStep, setCurrentStep] = useState(1)
    const [jobData, setJobData] = useState<any>([])
    const handleClick = (newStep: number) => setCurrentStep(newStep)

    const {isLoading, data, refetch} = useQuery(["job", params.id], async () => {
        const res = await https.get(`${endpoints.jobs}/${params.id}?relations=domain:id,name;jobStatus:id,name;experienceLevel:id,name;contractType:id,name;studyLevel:id,name;company:id,company_name;salaryMethod:id,name;staff:id,name;user:id,email;domainFormations:id,name;domainJobs:id,name;subDomains:id,name;primaryLanguages:id,name;secondaryLanguages:id,name;softSkills:id,name;technicalSkills:id,name;jobApplyFormQuestions:id,title;recruitmentStepActions;recruitmentStepActions.recruitmentStepAction;jobApplyFormDocuments:id,title,description,type`)
        return res.data
    }, {staleTime: 30000, enabled: !!params.id})
    const job = data || null

    const finalRender = (step: number) => {
        switch (step) {
            case (2):
                return <StepOne
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            case (3):
                return <StepTwo
                    defaultData={job || {} }
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            case (4):
                return <StepThree
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
            default:
                return <StepFour
                    steps={steps}
                    currentStep={currentStep}
                    onClick={handleClick}
                    onConfirm={() => {
                    }}
                />
        }
    }

    return (
        <>
            <TitleBar
                title="Create a new job offer"
                subTitle="Fill in all required fields, save progressively and publish your offer"
            />
            <div className="grid grid-cols-4 px-5 py-5 gap-x-7">
                <div className="col-span-1">
                    <Stepper type="metromode" steps={steps} currentStep={currentStep}/>
                </div>
                <div className="col-span-3">
                    <StepperContext.Provider value={{stepData: jobData, setStepData: setJobData}}>
                        {!params.id ? finalRender(currentStep) : isLoading ? <div>Loding</div> : finalRender(currentStep)}
                    </StepperContext.Provider>
                </div>
            </div>
        </>
    )
}

export default JobsCreate
