import React, {SyntheticEvent, useEffect, useState} from "react";
import {BsCheckCircleFill, BsCaretDown, BsCaretUp} from "react-icons/bs"
import { AppInput} from "../index";
import {RecruitmentAction, RecruitmentStepItem} from "../../types/CommonTypes";

type Props = {
    onReturn: Function,
    item: RecruitmentStepItem,
    actions: RecruitmentAction []
    defaultValues: {[key:string]: any}
    isExpended?: boolean
}
export const FlagSquare = ({color}: {color: string}) => {
    return(
        <p style={{backgroundColor: color}} className="w-4 h-4 m-0 rounded-sm inline-block"/>
    )
}

const CheckBox = ({isChecked = false, onCheck}:  {isChecked?: boolean, onCheck: (item: any) => typeof item }) => {
    return (
        <div onClick={onCheck} className="w-6">
            {isChecked ? <BsCheckCircleFill className="cursor-pointer text-green-600 font-bold text-2xl"/>: <div className="p-3 border rounded shadow-md cursor-pointer" />}
        </div>
    )
}

const RecruitmentStep = (props: Props) => {
    const [actions, setActions] = useState(props.actions)
    const [isExpended, setIsExpended] = useState(props?.isExpended)
    const [data, setData] = useState<{[key:string]: string}>({})

    const handleChange = (key: string, actionIndex: number) => async (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const value = target.value
        await setData({
            ...data,
            [key]: value,
        });
        const temp = actions.map((a, index) =>{
            return index === actionIndex ? {...a, data: data[key]} : a
        })
        await setActions(temp)
        props.onReturn(props.item.id, temp)
    };

    useEffect(()=> {
        const tempDefaultValues = props.actions.map((item, index) => {
            const isSelected = props.defaultValues[index] && props.defaultValues[index]?.isSelected
            const defaultInputValue =  props.defaultValues[index] && props.defaultValues[index]?.isSelected ? props.defaultValues[index]?.data : ""
            if(isSelected){
                setData({
                    ...data,
                    [index]: defaultInputValue,
                });
                return {...item, isSelected}
            }
           return item
        })
        setActions(tempDefaultValues)
    }, [])

    const onActionClick = async (item: RecruitmentAction) => {
        const temp = actions.map(a =>{
            return a.title === item.title ? {...a, isSelected: !a.isSelected} : a
        })
        await setActions(temp)
        props.onReturn(props.item.id, temp)
    }

    return(
        <div className="border border-gray-300 border-1 py-4 px-4 rounded-md mb-4">
            <div className="flex items-center justify-between">
                <div className="mr-3">
                    <FlagSquare color={props.item.flag_color} />
                </div>
                <div className="basis-10/12">
                    <h4 className="font-semibold text-md cursor-pointer" onClick={() => setIsExpended(!isExpended)}> {props.item.title}</h4>
                    <p className="text-sm text-slate-500">{props.item.description}</p>
                </div>

                <div className="">
                    {isExpended ?
                        <BsCaretUp
                            className="rounded-icon-button"
                            title="Deplier"
                            onClick={() => setIsExpended(!isExpended)}
                        /> :
                        <BsCaretDown
                            className="rounded-icon-button"
                            title="Replier"
                            onClick={() => setIsExpended(!isExpended)}
                        />}
                </div>
            </div>

            {isExpended ?
                <div>
                    {actions.map((item, index) => (
                        <div key={index} className="flex items-start border p-3 rounded mb-3">
                            <CheckBox onCheck={() => onActionClick(item)} isChecked={item.isSelected} />
                            <div className="ml-4 w-full">
                                <div onClick={() => onActionClick(item)} className="font-bold text-slate-600 text-sm cursor-pointer">{item.title}</div>
                                <div className="text-[12px]">{item.description}</div>
                                {item.isSelected && index !== 0 ? <div className="mt-3">
                                    <AppInput
                                        name={`action_${index}`}
                                        isTextarea
                                        rows={4}
                                        value={data[index]}
                                        onChange={handleChange(index.toString(), index)}
                                    />
                                </div>: null}
                            </div>
                        </div>
                    ) )}
                </div> : null}
        </div>
    )
}

export default RecruitmentStep
