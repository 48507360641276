import {useLocation, useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {Controller, useForm} from "react-hook-form";
import routes from "../../constants/routes";
import {AppButton, AppInput, AppSelect, Stepper, TitleBar} from "../../components";
import React, {SyntheticEvent, useState} from "react";
import {applyDocumentFormats, newJobSteps} from "../../constants/common";
import {
    UseGetLanguages,
    UseGetProfessionalExperienceLevels, UseGetSoftSkills,
    UseGetStudyDomains,
    UseGetStudyLevels, UseGetTechSkills
} from "../../services";
import {BsFileEarmarkText, BsInputCursorText, BsTrash} from "react-icons/bs";

type DynamicFields = {
    type: "file" | "question",
    title: string,
    format: string
}

const JobsCreateStepTwo = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const detailsData = state?.detailsData
    const profileData = state?.profileData
    if (!detailsData) window.location.href = routes.jobCreateOfferDetails
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: "onSubmit", defaultValues: {
            years_experience_required: profileData?.years_experience_required,
            experience_level_id: profileData?.experience_level_id,
            study_level_id: profileData?.study_level_id,
            domainFormations: profileData?.domainFormations,
            primaryLanguages: profileData?.primaryLanguages,
            secondaryLanguages: profileData?.secondaryLanguages,
            technicalSkills: profileData?.technicalSkills,
            softSkills: profileData?.softSkills,
        }
    },);

    const [dynamicFields, setDynamicFields] = useState<DynamicFields[]>(profileData?.dynamicFields || [])

    const {data: studyLevels, isLoading: isGettingStudyLevels} = UseGetStudyLevels()
    const {data: experienceLevels, isLoading: isGettingExperienceLevels} = UseGetProfessionalExperienceLevels()
    const {data: studyDomains, isLoading: isGettingStudyDomains} = UseGetStudyDomains()
    const {data: languages, isLoading: isGettingLanguages} = UseGetLanguages()
    const {data: softSkills, isLoading: isGettingSoftSkills} = UseGetSoftSkills()
    const {data: techSkills, isLoading: isGettingTechSkills} = UseGetTechSkills()

    const addDynamicField = (type: "file" | "question") => {
        setDynamicFields([...dynamicFields, {title: "", format: "", type}])
    }

    const deleteDynamicField = async (index: number) => {
        let temp = [...dynamicFields];
        temp.splice(index, 1)
        setDynamicFields(temp)
    }

    const handleDynamicFormChange = (index: number, item: DynamicFields) => (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        let data = [...dynamicFields];
        data[index] = {...item, title: target.value}
        setDynamicFields(data);
    }

    const handleDynamicSelect = (option: { id: string, name: string }, index: number, item: DynamicFields) => {
        let data = [...dynamicFields];
        data[index] = {...item, format: option.name}
        setDynamicFields(data);
    }

    const onSubmit = (data: any) => {
        const body = {
            ...data,
            dynamicFields: dynamicFields
        }
        navigate(routes.jobCreateRecruitmentProcess, {state: {...state, profileData: body}})
    }

    return (
        <div>
            <TitleBar
                title="Créer une offre d'emploi"
                subTitle="Remplissez tous les champs requis, enregistrer progressivement et publier votre offre des qu'elle est prete"
            />
            <div className="grid grid-cols-4 px-5 py-5 gap-x-7">
                <div className="col-span-1">
                    <Stepper type="metromode" steps={newJobSteps} currentStep={2}/>
                </div>
                <motion.form
                    initial={{width: 0}}
                    animate={{width: "100%"}}
                    exit={{x: window.innerWidth, transition: {duration: 0.2}}}
                    onSubmit={handleSubmit(onSubmit)} className="col-span-3">
                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-4 font-bold text-lg">Expérience requise</h4>
                        <div className="mb-2 grid grid-cols-3 gap-x-4">
                            <AppInput
                                {...register("years_experience_required", {required: "Entrez le nombre d'année d'expérience requise"})}
                                label="Nombre d'année d'expérience"
                                type="text"
                                name="years_experience_required"
                                error={!!(errors && errors["years_experience_required"])}
                                errorMessage={errors["years_experience_required"]?.message?.toString()}
                            />
                            <Controller
                                control={control}
                                name="experience_level_id"
                                rules={{
                                    required: {value: true, message: "Selectionnez le niveau professionnel"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Niveau professionnel"
                                        name="experience_level_id"
                                        selectedValue={profileData?.experience_level_id?.name}
                                        text="name"
                                        value="id"
                                        isLoading={isGettingExperienceLevels}
                                        data={experienceLevels?.data}
                                        error={!!(errors && errors["experience_level_id"])}
                                        errorMessage={errors["experience_level_id"]?.message?.toString()}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="study_level_id"
                                rules={{
                                    required: {value: true, message: "Selectionnez le niveau d'étude"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Niveau d'étude"
                                        name="study_level_id"
                                        selectedValue={profileData?.study_level_id?.name}
                                        text="name"
                                        value="id"
                                        isLoading={isGettingStudyLevels}
                                        data={studyLevels?.data}
                                        error={!!(errors && errors["study_level_id"])}
                                        errorMessage={errors["study_level_id"]?.message?.toString()}
                                    />
                                )}
                            />

                        </div>
                        <div className="">
                            <Controller
                                control={control}
                                name="domainFormations"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Selectionnez un ou plusieurs domaine de formation"
                                    },
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Domaine d'etudes et formations"
                                        name="domainFormations"
                                        text="name"
                                        value="id"
                                        multiple
                                        selectedValueMulti={profileData?.domainFormations}
                                        isLoading={isGettingStudyDomains}
                                        data={studyDomains?.data}
                                        error={!!(errors && errors["domainFormations"])}
                                        errorMessage={errors["domainFormations"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-4 font-bold text-lg">Compétences</h4>
                        <div className="mb-2 grid grid-cols-2 gap-x-4">
                            <Controller
                                control={control}
                                name="primaryLanguages"
                                rules={{
                                    required: {value: true, message: "Selectionnez la langue de travail"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Langue de travail"
                                        name="primaryLanguages"
                                        selectedValue={profileData?.primaryLanguages?.name}
                                        text="name"
                                        value="id"
                                        isLoading={isGettingLanguages}
                                        data={languages?.data}
                                        error={!!(errors && errors["primaryLanguages"])}
                                        errorMessage={errors["primaryLanguages"]?.message?.toString()}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="secondaryLanguages"
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Autres langues"
                                        name="secondaryLanguages"
                                        text="name"
                                        value="id"
                                        multiple
                                        selectedValueMulti={profileData?.secondaryLanguages}
                                        isLoading={isGettingStudyDomains}
                                        data={languages?.data}
                                    />
                                )}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-x-4">
                            <Controller
                                control={control}
                                name="technicalSkills"
                                rules={{
                                    required: {value: true, message: "Selectionnez une ou plusieurs compétences"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Compétences techniques"
                                        name="technicalSkills"
                                        text="name"
                                        value="id"
                                        multiple
                                        selectedValueMulti={profileData?.technicalSkills}
                                        isLoading={isGettingTechSkills}
                                        data={techSkills?.data}
                                        error={!!(errors && errors["technicalSkills"])}
                                        errorMessage={errors["technicalSkills"]?.message?.toString()}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="softSkills"
                                rules={{
                                    required: {value: true, message: "Selectionnez une ou plusieurs compétences"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        label="Compétences générales"
                                        name="softSkills"
                                        text="name"
                                        value="id"
                                        multiple
                                        selectedValueMulti={profileData?.softSkills}
                                        isLoading={isGettingSoftSkills}
                                        data={softSkills?.data}
                                        error={!!(errors && errors["softSkills"])}
                                        errorMessage={errors["softSkills"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="bg-white border-gray-300 border-1 p-5 mb-5 rounded-lg">
                        <h4 className="mb-0 font-bold text-lg">Questions de présélection</h4>
                        <p className="text-xs mb-4 text-gray-500">Les candidats devront répondre à ces questions ou
                            joindre des documents spécifiques avant de pouvoir postuler</p>
                        <div>
                            <AppButton
                                label="Ajouter une question"
                                size="sm"
                                icon={<BsInputCursorText/>}
                                textColor="text-gray-700 hover:text-white"
                                bgColor="bg-white hover:bg-gray-900 border border-gray-700"
                                onClick={() => addDynamicField("question")}
                            />
                            <AppButton
                                label="Ajouter un fichier à joindre"
                                size="sm"
                                icon={<BsFileEarmarkText/>}
                                className="ml-3"
                                textColor="text-yellow-700 hover:text-white"
                                bgColor="bg-white hover:bg-yellow-900 border border-yellow-700"
                                onClick={() => addDynamicField("file")}
                            />
                        </div>
                        <div className="mt-5 mb-5">
                            {dynamicFields && dynamicFields.length ? dynamicFields.map((item, index) => (
                                    <div key={index} className="grid grid-cols-12 items-center mt-4 gap-4">

                                        {item.type === "question"
                                            ? (<div className="col-span-10">
                                                <AppInput
                                                    label="Saisissez la question"
                                                    className="w-full"
                                                    name={"hello"}
                                                    value={dynamicFields[index].title}
                                                    onChange={handleDynamicFormChange(index, item)}
                                                />
                                            </div>)

                                            : (<div className="col-span-10 ">
                                                <div className="grid grid-cols-6 gap-4">
                                                    <div className="col-span-4">
                                                        <AppInput
                                                            label="Déscription du fichier à joindre"
                                                            className="w-full"
                                                            name="fileTitle"
                                                            value={dynamicFields[index].title}
                                                            onChange={handleDynamicFormChange(index, item)}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <AppSelect
                                                            label="Format"
                                                            name="format"
                                                            data={applyDocumentFormats}
                                                            text="name"
                                                            value="id"
                                                            onChange={(option: {
                                                                id: string,
                                                                name: string
                                                            }) => handleDynamicSelect(option, index, item)}
                                                            selectedValue={dynamicFields[index].format}
                                                            isClearable={false}
                                                        />
                                                    </div>

                                                </div>
                                            </div>)}
                                        <div className="col-span-2 justify-self-center">
                                            <BsTrash
                                                onClick={() => deleteDynamicField(index)}
                                                title="Supprimer ce champs"
                                                className="mb-5 text-2xl cursor-pointer text-red-600 rounded-full w-7 h-7 p-1 font-bold border border-red-700 block"/>

                                        </div>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <AppButton
                            size="md"
                            type="submit"
                            className="w-[30%] rounded-[50px] py-4"
                            bgColor="bg-white border hover:border-gray-600 hover:bg-gray-600"
                            textColor="text-gray-600 hover:text-white"
                            label="Précédent"
                            onClick={() => navigate(routes.jobCreateOfferDetails, {state: {...state}})}
                        />

                        <AppButton
                            size="md"
                            type="submit"
                            className="w-[50%] rounded-[50px] py-4"
                            bgColor="bg-primary-base hover:bg-primary-hover"
                            textColor="text-white"
                            label="Enregistrer et avancer"
                        />
                    </div>
                </motion.form>
            </div>
        </div>
    )
}

export default JobsCreateStepTwo
