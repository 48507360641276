import {useQuery} from "react-query";
import {FormatUrlParams, GetQueryKey} from "../../utils";
import {https} from "../index";
import endpoints from "../../constants/endpoints";
import {cacheTime} from "../../constants/common";

type QueryParams = {
    status?: string
}

export const UseGetStudyDomains = (options?: QueryParams) =>
    useQuery(GetQueryKey("studyDomains", options), async () => https.get(`${endpoints.domainFormations}${FormatUrlParams(options)}`), {staleTime: cacheTime.long});
