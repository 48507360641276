import en from "./svg/en.svg"
import fr from "./svg/fr.svg"
const logoLight = require("./images/logo/logo-light.png")
const smallLogoLight = require("./images/logo/small-logo-light.png")
const logoWhite = require("./images/logo/logo-white.png")
const smallLogoWhite = require("./images/logo/small-logo-white.png")
const defaultAvatar = require("./images/common/default-avatar.png")
const bgLogin = require("./images/common/bgLogin.jpg")
const bgSignup = require("./images/common/bgSignup.jpg")

export const images = {
    logoLight,
    smallLogoLight,
    logoWhite,
    smallLogoWhite,
    defaultAvatar,
    bgLogin,
    bgSignup,
    en, fr
}
