import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useLocation} from "react-router-dom";
import {images} from "../../assets/images";
import {AppButton, AppInput, AppPhoneInput, AppSelect} from "../../components";
import routes from "../../constants/routes";
import {BsArrowLeft} from "react-icons/bs"
import {UseGetCities, UseGetCountries, UseSignup} from "../../services";
import {ItemType} from "../../types/CommonTypes";
import {ToastNotif} from "../../helpers";

const RegisterStepTwo = () => {
    const navigate = useNavigate()
    const {state} = useLocation()
    const userData = state?.userData
    if (!userData) window.location.href = routes.signUp
    const [selectedCountry, setSelectedCountry] = useState<ItemType>()
    const selectCityRef: any = useRef();

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "onSubmit"});

    const {data: countries, isLoading: isCountriesLoading} = UseGetCountries()
    const {data: cities, isLoading: isCitiesLoading} = UseGetCities({countryName: selectedCountry?.country_name})
    const {data: signupData, isLoading: isSigningup, mutate: signup} = UseSignup()

    useEffect(() => {
        if (signupData) {
            if (signupData.error) {
                ToastNotif.simple("Une erreur ou plusieurs se sont produit, veuillez réessayez SVP", "error")
                return
            } else {
                ToastNotif.simple("Votre inscription a reussi avec succès ! 😊", "success")
                navigate(routes.login)
            }
        }
    }, [signupData])

    const onSubmit = (data: any) => {
        const body = {
            ...userData,
            ...data,
            company_country: selectedCountry?.country_name,
            company_city: data?.company_city?.name
        }
        signup(body)
    }

    return (
        <div className="grid m-0  h-screen w-screen md:grid-cols-6 grid-cols-1 bg-white overflow-x-hidden">
            <div
                className="w-full h-full md:col-span-3 hidden md:block bg-primary-base">
                <p className="pt-8 pl-8"><img src={images.logoWhite} className="w-1/3" alt="Logo Darketix"/></p>

            </div>
            <div className="col-span-3 flex flex-col justify-center px-16">
                <div className="relative">
                    <div className="mb-5">
                        <BsArrowLeft
                            onClick={() => navigate(routes.signUp, {state: {userData}})}
                            title="Page précédente"
                            className="mb-5 text-2xl rounded-full w-7 h-7 p-1 font-bold border border-slate-700 block cursor-pointer"/>

                        <h2 className="text-gray-800 text-3xl font-bold">Votre Entreprise</h2>
                        <p className="text-gray-500 text-xs">Enregistrez les informations relatives à votre
                            entreprise.</p>
                    </div>
                    <form className="grid gap-x-4 md:grid-cols-2 grid-cols-1" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-span-2 mb-2">
                            <AppInput
                                {...register("company_name", {required: "Entrez le nom de votre entreprise"})}
                                label="Nom de votre entreprise"
                                type="text"
                                name="company_name"
                                error={!!(errors && errors["company_name"])}
                                errorMessage={errors["company_name"]?.message?.toString()}
                            />
                        </div>

                        <div>
                            <AppInput
                                {...register("company_email", {required: "Entrez l'adresse email de l'entreprise"})}
                                label="Email de votre entreprise"
                                type="email"
                                name="company_email"
                                error={!!(errors && errors["company_email"])}
                                errorMessage={errors["company_email"]?.message?.toString()}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="company_phone"
                                rules={{
                                    required: {value: true, message: "Entrez le numéro téléphone de votre entreprise"},
                                }}
                                render={({field}) => (
                                    <AppPhoneInput
                                        {...field}
                                        name="company_phone"
                                        label="Téléphone de votre entreprise"
                                        required
                                        error={!!(errors["company_phone"])}
                                        errorMessage={errors["company_phone"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>

                        <div className="mb-2 mt-2">
                            <AppSelect
                                label="Pays"
                                name="company_country"
                                text="country_name"
                                value="country_name"
                                isLoading={isCountriesLoading}
                                onChange={(v: ItemType) => {
                                    setSelectedCountry(v)
                                    selectCityRef?.current?.clearValue();
                                }}
                                data={countries}
                                error={!!(errors && errors["company_country"])}
                                errorMessage={errors["company_country"]?.message?.toString()}
                            />
                        </div>
                        <div className="mb-2  mt-2">
                            <Controller
                                control={control}
                                name="company_city"
                                rules={{
                                    required: {value: true, message: "Entrez la ville de l'entreprise"},
                                }}
                                render={({field}) => (
                                    <AppSelect
                                        {...field}
                                        ref={selectCityRef}
                                        label="Ville"
                                        name="company_city"
                                        isLoading={isCitiesLoading}
                                        text="name"
                                        value="name"
                                        data={cities}
                                        placeholder={selectedCountry ? "Selectionner..." : "Selectionnez d'abord un pays"}
                                        error={!!(errors && errors["company_city"])}
                                        errorMessage={errors["company_city"]?.message?.toString()}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-span-2">
                            <AppInput
                                {...register("company_address", {required: "Entrez l'adresse de votre entreprise"})}
                                label="Adresse"
                                type="text"
                                name="company_address"
                                error={!!(errors && errors["company_address"])}
                                errorMessage={errors["company_address"]?.message?.toString()}
                            />
                        </div>

                        <div className="col-span-2 mt-5">
                            <AppButton
                                isLoading={isSigningup}
                                size="md"
                                type="submit"
                                className="w-[100%] rounded-[50px] py-4"
                                bgColor="bg-primary-base hover:bg-primary-hover"
                                textColor="text-white"
                                label="Valider"
                            />

                        </div>

                    </form>

                </div>

            </div>
        </div>
    )
}

export default RegisterStepTwo
