import React from "react";
import ContentLoader from "react-content-loader";

const JobListItemPreloader = () => {
    return(
        <div className="border border-gray-300 px-4 py-4 rounded mb-3  border-l-4 bg-white">
            <ContentLoader
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                className="w-full h-[78px] mb-3"
            >
                <rect x="0" y="8" rx="3" ry="3" height="12" className="w-[20%]" />
                <rect x="0" y="30" rx="3" ry="3" height="8" className="w-[10%]" />
                <rect x="11%" y="30" rx="3" ry="3" height="8" className="w-[20%]" />
                <rect x="0" y="45" rx="3" ry="3" height="9" className="w-[65%]" />
                <rect x="0" y="70" rx="3" ry="3" height="9" className="w-[98%]" />
                <rect x="92%" y="8" rx="3" ry="3" height="25" className="w-[60px]" />
            </ContentLoader>
        </div>
    )
}

export default JobListItemPreloader
