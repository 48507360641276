import React from "react";

type PropsType = {
    rightTitle?: string | React.ReactNode
    amount: string | React.ReactNode
    currency?: string | React.ReactNode
    subTitle: string | React.ReactNode
    className?: string
}

const GreatNumberCard = ({rightTitle, amount, currency, subTitle, className}: PropsType) => {
    return (
        <div className={`border border-slate-500  p-4 flex flex-col justify-center items-center rounded ${className}`}>
            {rightTitle ? <div className="self-end text-[10px] text-slate-500">{rightTitle}</div> : null}
            <div className="text-3xl font-bold mt-4">{amount}{currency ? <span className="text-xs">USD</span>: null}</div>
            {subTitle ? <div className="text-[10px] font-semibold text-slate-600">{subTitle}</div> : null}
        </div>
    )
}

export default GreatNumberCard
