export type HorizontalMenusPropsType = {
    title?: string
    isActive?: boolean
    [key: string]: any
}
type Props = {
    menus: HorizontalMenusPropsType[]
    onItemClick: Function
}

const HorizontalMenus = ({menus, onItemClick}: Props) => {

    return(
        <ul className="flex flex-row gap-x-7 mt-5">
            {
                menus.map((item, index) => {
                    return (<li key={index} onClick={() => onItemClick(index)} className={`${item.isActive ? "border-b-4 border-primary-base text-primary-base" : "text-gray-700"} cursor-pointer font-bold text-sm tracking-wider hover:text-primary-hover pb-3 `}>
                        {item.title}
                    </li>)
                })
            }
        </ul>
    )
}

export default  HorizontalMenus
