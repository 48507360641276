import {useState} from "react";
import {BlockingModal, LottieFile} from "../../components";
import applause from "../../assets/lotties/applause.json";
import {Configuration} from "../index";
import {auth} from "../../services";

const Welcome = () => {
    const connectedUser = auth.getCurrentUser()
    const connectedCompany = auth.getCurrentCompany()
    const [showConfig, setShowConfig] = useState<boolean>(false)

    return (
        <>
            {!showConfig && connectedUser && connectedUser.hasVerifiedEmail && connectedCompany && !connectedCompany.isConfigured &&
                <BlockingModal
                    title={<div className="dark:text-white text-2xl" >Une dernière étape ...</div>}
                    description={<div className="mb-8 mt-3 text-xs dark:text-white">
                        Configurons ensemble vos informations de base pour profiter d'une meilleure experience sur <strong>Darketix</strong> 😊.</div>}
                    validateLabel={"Oui, continuer !"}
                    onValidate={() => setShowConfig(true)}
                    icon={<LottieFile animationData={applause} width={120} height={120}/>}
                />}

            {showConfig && <Configuration/>}
        </>
    )
}

export default Welcome
