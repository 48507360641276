import React, {useState} from "react";
import commonFunctions from "../../utils/CommonFunctions";
import {AppButton, DeleteModal, Dropdown, OverLoader} from "../../components";
import {BsCalendar2Check, BsArchive, BsTrash, BsCalendarWeek} from "react-icons/bs";
import {AiOutlineMore} from "react-icons/ai"
import {HiStatusOnline} from "react-icons/hi"
import {FiEdit3, FiMapPin} from "react-icons/fi"
import routes from "../../constants/routes";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useMutation} from "react-query";
import {https} from "../../services";
import {ToastNotif} from "../../helpers";
import JobStatus from "./JobStatus";
import endpoints from "../../constants/endpoints";

type Props = {
    job : {
        title: string
        id: string
        description: string
        slug: string
        status: string
        city: string
        country: string
        job_status_id: string
        created_at: string
    },
    onActionClick: () => void
}


const JobListItem = (props: Props) => {
    const navigate = useNavigate()
    const {job, onActionClick} = props
    const [openModal, setOpenModal] = useState<boolean>(false)
    const {isLoading: isDeleting, mutate: deleteJob} = useMutation(async () => {
        const result =  await https.delete(`${endpoints.jobs}/${job.id}`)
        if(!result.error){
            onActionClick()
            ToastNotif.simple(result.description, "success")
            return
        }
        ToastNotif.simple(result.description, "error")
    })

    const {isLoading: isChangingStatus, mutate: changeStatus} = useMutation(async (body: {status: string}) => {
        const result =  await https.patch(`${endpoints.changeJobStatus}${job.id}`, {status_id: body.status})
        if(!result.error){
            ToastNotif.simple(result.description, "success")
            onActionClick()
            return
        }
        ToastNotif.simple(result.description, "error")
    })

    const onDelete = () => {
        setOpenModal(false)
        deleteJob()
    }
    const menus = [
        [
            {
                type: "custom",
                menuItem: {icon: <HiStatusOnline className="text-green-600"/>, onClick: ()=>{changeStatus({status: "2"})}, title: "Publier" }
            },
            {
                type: "custom",
                menuItem: {icon: <BsArchive/>, onClick: ()=>{changeStatus({status: "5"})}, title: "Archiver"}
            },
            {
                type: "custom",
                menuItem: {icon: <BsCalendar2Check className="text-primary-base"/>, onClick: ()=>{changeStatus({status: "3"})}, title: "Cloturer"}
            },
        ],
        [
            {
                type: "custom",
                menuItem: {icon: <FiEdit3 className="text-blue-600"/>, onClick: () => navigate(`${routes.jobEdit}/${job.id}`), title: "Modifier"}
            },
            {
                type: "custom",
                menuItem: {icon: <BsTrash className="text-red-600"/>, onClick: ()=>{ setOpenModal(true)}, title: "Supprimer"}
            },
        ],
    ]
    return(
        <div className="border border-gray-300 px-4 py-4 rounded mb-3  border-l-4 ">
            {openModal && <DeleteModal isOpen={openModal} onCancel={() => setOpenModal(false)} onValidate={() => onDelete()}/>}
            {(isChangingStatus || isDeleting) && <OverLoader/>}
            <div className="flex flex-row justify-between">
                <div className="font-bold text-lg text-slate-900 cursor-pointer" onClick={() => navigate(`${routes.jobs}/${job.id}`)}>{job.title}</div>
                <div>
                    <Dropdown
                        title="Options"
                        position="right"
                        menus={menus}
                        type="custom"
                        titleComponent={<AppButton isButton={false}
                                                   //color="default-outline"
                                                   icon={<AiOutlineMore />} size="xs" category="bloc" label="Actions" />}
                    />
                </div>
            </div>
            <div className="text-xs flex flex-row gap-4">
                <div><JobStatus  status={job.job_status_id.toString()} /></div>
                <div><FiMapPin className="inline align-top mr-1" /> <span>{job.city}, {job.country}</span></div>
                <div><BsCalendarWeek className="inline align-top mr-1" /> <span> {moment(job.created_at).format('lll')}</span></div>
            </div>
            <p className="text-slate-800 text-sm mt-2">{commonFunctions.trailingText(job.description, 120)}</p>

            <div className="flex flex-row justify-between border-t mt-4 pt-3">
                <div>
                    <div><span className="font-bold">4</span><span className="text-xs text-slate-500"> Candidatures</span></div>
                </div>
            </div>
        </div>
    )
}

export default JobListItem
